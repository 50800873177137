import React, { Component } from "react";
import { authProvider } from "../AuthProvider";
import Axios from "axios";
import { API_ROUTE, LEGACY_SITE, Routes } from "../Helpers/Constants";
import { Button } from "reactstrap";
//import { CopyToClipboard } from "react-copy-to-clipboard";
//import Spinner from "reactstrap/lib/Spinner";
import LoaderGif from "../images/365 loading.gif"
export class AzureBackupAccountProgress extends Component {
    constructor(props) {
        super(props);
        let page = false;
        if (props.page) {
            page = props.page;
        }
        var orgId = props.orgId;
        var _token = props.token;
        this.state = {
            inProgress: false,
            done: false,
            errorMessage: "",
            page: page,
            orgId: orgId,
            token: _token
        };

        //this.getDeviceCode = this.getDeviceCode.bind(this);
        this.addApplications = this.addApplications.bind(this);
    }
    static displayName = AzureBackupAccountProgress.name;

    removeNavBar() {
        if (!this.state.page) {
            var oldLinks = document.head.childNodes;
            var navBarLinks = document.body.getElementsByClassName("row titlebar custStyle");
            for (var i = 0; i < navBarLinks.length; i++) {
                var link = navBarLinks[i];
                link.parentNode.removeChild(link);
            }
        }
    }

    async componentDidMount() {
        this.removeNavBar();
    }

    //async getDeviceCode() {
    //    var token = await authProvider.getAccessToken();
    //    let tokenString = token.accessToken;
    //    if (this.state.isMfa) {
    //        this.setState({
    //            inProgress: true
    //        }, () => {
    //            const config = {
    //                headers: {
    //                    Authorisation: `Bearer ` + tokenString
    //                },
    //            };
    //            let data = {
    //                url: window.location.origin
    //            };
    //            Axios.post(API_ROUTE + Routes.GET_DEVICE_CODE, data, config)
    //                .then((response) => {
    //                    this.setState({
    //                        deviceCode: response.data.message,
    //                        hasDeviceCode: true,
    //                        inProgress: false
    //                    })
    //                })
    //                .catch((reason) => {
    //                    let message = "";
    //                    if (reason) {
    //                        if (reason.response) {
    //                            if (reason.response.data) {
    //                                message = reason.response.data;
    //                            }
    //                        }
    //                    }
    //                    this.setState(
    //                        {
    //                            errorMessage: message,
    //                            inProgress: false,
    //                            error: true
    //                        });
    //                });
    //        });
    //    }
    //}

    async addApplications() {
        var token = await authProvider.getAccessToken();
        let tokenString = token.accessToken;

        this.setState({
            inProgress: true
        }, () => {
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString
                },
            };
            let data = {
                OrgId: this.state.orgId
            };
            Axios.get(API_ROUTE + Routes.ADD_AZURE_ACCOUNTS, config)
                .then((response) => {
                    console.log(response);
                    if (response.message.includes("Already exists")) {
                        this.setState(
                            {
                                errorMessage: "Azure application already exists",
                                inProgress: false,
                                error: true
                            });
                    }
                    this.setState({
                        done: true,
                        inProgress: false
                    })
                })
                .catch((reason) => {
                    let message = "";
                    if (reason) {
                        if (reason.response) {
                            if (reason.response.data) {
                                message = reason.response.data;
                            }
                        }
                    }
                    this.setState(
                        {
                            errorMessage: message,
                            inProgress: false,
                            error: true
                        });
                });
        });
    }

    render() {
        let spinner = null;
        if (this.state.inProgress) {
            spinner = <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                <img src={LoaderGif} alt="loading" style={{ height: '45px' }} />
            </div> 
        }
        if (this.state.error) {
            return (
                <div style={{ backgroundColor: "white", backgroundImage: "none" }}>
                    {spinner}
                    <p>Error.</p>
                    <p>{this.state.errorMessage}</p>
                </div>);
        } else if (this.state.done) {
            return (
                <div style={{ backgroundColor: "white", backgroundImage: "none" }}>
                    {spinner}
                    <p>Backup Applications succesfully added.</p>
                    <Button onClick={() => { window.location.href = "settings" }}>Okay</Button>

                </div>
            );
        }
        let backButton = (<a href={"/settings"} className="btn">Back</a>)
        let button;

        button = (<Button onClick={this.addApplications} disabled={this.state.inProgress}>Add Azure Backup Applications</Button>);
        return (
            <div style={{ backgroundColor: "white", backgroundImage: "none" }}>
                {spinner}
                <p>If you want to backup object in your Azure environment we will need to create a new application in your tenancy,
                we will call this CCAzureBackupRead. This application will have the permissions needed to backup your environment.</p>
                <p>You must be a global administrator to perform this action..</p>
                {button}
                {backButton}
            </div>
        );
        //if (this.state.inProgress) {
        //    return (
        //        <div style={{ backgroundColor: "white", backgroundImage: "none" }}>
        //            {spinner}
        //            <p>In Progress</p>
        //        </div>
        //    );
        //} else {
        //    return (
        //        <div style={{ backgroundColor: "white", backgroundImage: "none" }}>
        //            {spinner}
        //            <p>Add auxillary backup accounts to process SharePoint and OneDrive for Business data. Using multiple accounts allows to increase backup performance and avoid throttling.</p>
        //            <p>Current Additional Backup {nameString}: {this.state.count}</p>
        //            <b>You must be logged in with a Global admin user for the tenant account you are updating.</b>
        //            <p>How many {nameString} do you want</p>
        //            {button}
        //            {backButton}
        //        </div>
        //    );
        //}
    }
}