import React, { Component } from "react";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import loginIcon from "../../images/loginIcon.PNG";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import {
    Modal,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { LEGACY_SITE } from "../../Helpers/Constants";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import Axios from "axios";
import { ViewBankAccount } from "../DirectDebitComponents/ViewBankAccount";
import { AddBankAccount } from "../DirectDebitComponents/AddBankAccount";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardHeader from "reactstrap/lib/CardHeader";
import CardFooter from "reactstrap/lib/CardFooter";
import ddlogo from "../../images/ddlogo.png";
import gclogo from "../../images/gclogo.png";
import vdcsmenulogo from "../../images/vdcsmenulogo.png";
import ModalHeader from "reactstrap/lib/ModalHeader";
import LogRocket from "logrocket";
import AuthenticationContext from "../../Store/Authentication-Context";

export class DirectDebitSettings extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        // const orgId = urlParams.get("signkey") ? urlParams.get("signkey") : "";
        this.state = {
            hasBankAccount: false,
            bankAccount: null,
            loading: true,
            fetchedBankAccount: false,
            adding: false,
            showGuarantee: false,
            orgId: urlParams.get("signkey") ? urlParams.get("signkey") : ""
        };
        this.getBankAccount = this.getBankAccount.bind(this);

        this.submitted = this.submitted.bind(this);
        this.toggleGuarantee = this.toggleGuarantee.bind(this);
    }
    static displayName = DirectDebitSettings.name;

    componentDidMount() {
        //this.getBankAccount();
    }
   
    async getBankAccount() {
        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        this.setState(
            {
                loading: true,
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString },
                };
             
                Axios.get(API_ROUTE + Routes.GET_BANK_ACCOUNT + "/" + this.state.orgId, config)
                    .then((response) => {
                        let responseData = response.data;
                        if (!response.data) {
                            this.setState({
                                hasBankAccount: false,
                                adding: true,
                                fetchedBankAccount: true,
                                loading: false,
                            });
                        } else {
                            this.setState({
                                hasBankAccount: true,
                                bankAccount: responseData,
                                fetchedBankAccount: true,
                                loading: false,
                            });
                        }
                    })
                    .catch((reason) => {
                        this.setState({
                            fetchedBankAccount: true,
                            loading: false,
                        });
                    });
            }
        );
    }

    submitted(account) {
        this.setState({
            hasBankAccount: true,
            bankAccount: account,
            loading: false,
        });
    }

    async delete(event) {
        event.preventDefault();
        if (!this.state.loading) {
            const { setAuthContext } = this.context;
            var tokenString = await setAuthContext(new Date());
            this.setState(
                {
                    loading: false,
                },
                () => {
                    const config = {
                        headers: { Authorisation: `Bearer ` + tokenString },
                    };
                    Axios.get(API_ROUTE + Routes.DELETE_BANK_ACCOUNT + "/" + this.state.orgId, config)
                        .then((response) => {
                            this.setState({
                                hasBankAccount: false,
                                bankAccount: null,
                                loading: false,
                                adding: true,
                            });
                        })
                        .catch((reason) => {
                            this.setState({
                                loading: false,
                            });
                        });
                }
            );
        }
    }

    toggleGuarantee(event) {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            showGuarantee: !this.state.showGuarantee,
        });
    }

    render() {
        var section = (
            <AzureAD provider={loginAuthProvider} forceLogin={true}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            LogRocket.identify(accountInfo.account.userName, {
                                name: accountInfo.account.name
                            });
                            return (
                                <React.Fragment>
                                    <ViewBankAccount loginAuthProvider={loginAuthProvider} orgId={ this.state.orgId} />
                                </React.Fragment>
                            );
                        case AuthenticationState.Unauthenticated:
                            if (error) {
                                if (error.errorCode) {
                                    if (error.errorCode == "user_cancelled") {
                                        return <React.Fragment></React.Fragment>;
                                    } else {
                                        return <React.Fragment></React.Fragment>;
                                    }
                                } else {
                                    return <React.Fragment></React.Fragment>;
                                }
                            } else {
                                return <React.Fragment></React.Fragment>;
                            }
                        case AuthenticationState.InProgress:
                            return <React.Fragment></React.Fragment>;
                        default:
                            return <p>Error.</p>;
                    }
                }}
            </AzureAD>
        );
        let ddModal = (
            <Modal
                size="xl"
                isOpen={this.state.showGuarantee}
                borderColor="blue"
            >
                <ModalHeader toggle={this.toggleGuarantee}>
                    <div>
                        <b>Direct Debit Guarantee</b>
                    </div>
                </ModalHeader>
                <div style={{ padding: "20px", borderRadius: "0", borderColor: "blue" }}>
                    <div>
                        <Row>
                            <Col size="3" />
                            <Col size="8"><img src={ddlogo} /></Col>
                            <Col size="1" />
                        </Row>
                        <div>
                            The Guarantee is offered by all banks and building societies that
                            accept instructions to pay Direct Debits.
                            <br />
                            <br />
                        </div>
                        <div>
                            If there are any changes to the amount, date or frequency of your
                            Direct Debit GC re Virtual DCS Ltd will notify you (normally 3 working days)
                            in advance of your account being debited or as otherwise agreed.
                            If you request GC re Virtual DCS Ltd to collect a payment, confirmation of
                            the amount and date will be given to you at the time of the
                            request.
                            <br />
                            <br />
                        </div>
                        <div>
                            If an error is made in the payment of your Direct Debit, by
                            GC re Virtual DCS Ltd or your bank or building society, you are entitled to a
                            full and immediate refund of the amount paid from your bank or
                            building society.
                            <br />
                            <br />
                        </div>
                        <div>
                            If you receive a refund you are not entitled to, you must pay it
                            back when GC re Virtual DCS Ltd asks you to.
                            <br />
                            <br />
                        </div>
                        <div>
                            You can cancel a Direct Debit at any time by simply contacting
                            your bank or building society. Written confirmation may be
                            required. Please also notify GC re Virtual DCS Ltd.
                        </div>
                    </div>
                </div>
            </Modal>
        );
        return (
            <div data-private style={{ textAlign: "center", maxHeight: '547px', overflow: 'auto' }}>
                {ddModal}
                <Card>
                    <CardHeader>
                        <b>Manage Direct Debit</b>
                    </CardHeader>
                    <CardBody>
                        {section}
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="gc-logo">
                                <img src={gclogo} />
                            </Col>
                            <Col className="dd-logo">
                                <img src={vdcsmenulogo} />
                            </Col>
                        </Row>
                        <div>accounts@virtualdcs.co.uk | +44 345 388 8327</div>
                        <div>
                            <span>
                                Your payments are protected by the{" "}
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "blue",
                                    }}
                                    onClick={this.toggleGuarantee}
                                >
                                    Direct Debit Guarantee
                                </span>
                            </span>
                        </div>
                        <div>
                            Direct Debits provided in partnership with GoCardless.
                        </div>
                        <div>
                            <span>Read the&nbsp;</span>
                            <a href="https://gocardless.com/legal/privacy/">
                                <span>
                                    <span>GoCardless privacy notice</span>
                                </span>
                            </a>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        );
    }
}
