import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import LogRocket from "logrocket";
import React, { Component } from "react";
import { AuthenticationState, AzureAD } from "react-aad-msal";
import "../../CSS/general.css";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import AuthenticationContext from "../../Store/Authentication-Context";
import { RestoreTree } from "../RestoreTree";

export class Restore extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            orgId: urlParams.get("orgId") ? urlParams.get("orgId") : "",
            isValid: false,
        };

        this.itemClick = this.itemClick.bind(this);
        this.validateToken = this.validateToken.bind(this);
    }
    static displayName = Restore.name;

    itemClick(event, label) {
        event.preventDefault();
    }
    componentDidMount() {
        this.validateToken();
    }
    async validateToken(event) {
        if (event) {
            event.preventDefault();
        }
        const { setAuthContext } = this.context;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        let data = await Axios.get(API_ROUTE + Routes.VALIDATE_TOKEN + "/" + this.state.orgId, config)
            .then((response) => {

                let isValid = JSON.parse(response.data);
                if (isValid) {
                    this.setState({
                        isValid: true
                    });
                } else {
                    window.location.href = "/login";
                }
            })
            .catch((reason) => {
                //console.log("catch" + reason);
            });

    }

    render() {
        let restoreBlock = (
            <div>

            </div>
        );
        if (this.state.isValid) {
            restoreBlock = (<RestoreTree authContext={this.context} />);
        }

        let displaySection = (
            <AzureAD provider={loginAuthProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            LogRocket.identify(accountInfo.account.userName, {
                                name: accountInfo.account.name,
                            });
                            return (<div>{restoreBlock}</div>);
                        case AuthenticationState.Unauthenticated:
                            window.location.href = "/login";
                            return (
                                <React.Fragment>
                                    <div>Authentication Failed</div>
                                </React.Fragment>
                            );                       
                        default:
                            return <p>Please contact support.</p>;
                    }
                }}
            </AzureAD>
        );
        return (
            <React.Fragment>
                {displaySection}
            </React.Fragment>

        );
    }
}
