import React from "react";

const AccountInfoModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"

    };

    function closeModal(e) {
        e.stopPropagation();
        if (props.noClose) {
            window.parent.history.back();
        }
        else {           
            props.closeModal(false);
        }
    }    
    
    return (
        <div className="modal-xl">
            <div
                //onClick={closeModal}
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >
                <div className={props.action === "Invoicing" ? "modal-xl" : "modal-dialog modal-xl"} role="document">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.action}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal} ></button>
                        </div>
                        <div className="modal-body" >
                            <iframe id="AccountInfoId"
                                src={ props.url} 
                                style={{ height: "550px", width: "-webkit-fill-available" }}
                            >
                            </iframe>
                        </div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="AccountModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />


        </div>
    );
};

export default AccountInfoModal;
