import React, { Component } from "react";
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col
} from "reactstrap";
import Axios from "axios";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { Routes, API_ROUTE } from "../../Helpers/Constants";
import { Checkbox } from "semantic-ui-react";
import ddlogo from "../../images/ddlogo.png";
import AuthenticationContext from "../../Store/Authentication-Context";

export class AddBankAccount extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        const { orgId } = this.props;
    
        this.state = {
            firstName: "",
            lastName: "",
            companyName: "",
            address1: "",
            address2: "",
            city: "",
            postCode: "",
            email: "",
            accNumber: "",
            bankCode: "",
            branchCode: "",
            countryCode: "GB",
            currency: "GBP",
            iban: "",
            errorText: "",
            showError: false,
            mandateSetUp: false,
            authorised: true,
            showSummary: false,
            submitting: false,
            currencySelectOpen: false,
            countrySelectOpen: false,
            usingCompanyName: false,
            orgId:orgId
        };
        this.handleInput = this.handleInput.bind(this);
        this.createBankAccount = this.createBankAccount.bind(this);
        this.toggleCurrencySelect = this.toggleCurrencySelect.bind(this);
        this.toggleCountrySelect = this.toggleCountrySelect.bind(this);
        this.toggleCompanyName = this.toggleCompanyName.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        this.toggleAuthorised = this.toggleAuthorised.bind(this);
    }

    toggleCompanyName(event) {
        event.preventDefault();
        this.setState({
            usingCompanyName: !this.state.usingCompanyName,
            firstName: "",
            lastName: "",
            companyName: ""
        });
    }

    toggleCurrencySelect() {
        this.setState({
            currencySelectOpen: !this.state.currencySelectOpen,
        });
    }

    toggleCountrySelect() {
        this.setState({
            countrySelectOpen: !this.state.countrySelectOpen,
        });
    }

    handleInput(event) {
        var name = event.target.name;
        var value = event.target.value;
        if (name == "authorised") {
            value = event.target.checked;
        }
        this.setState(
            {
                [name]: value,
            },
            () => {
                if (name == "countryCode") {
                    if (value == "GB") {
                        this.setState({
                            accNumber: "",
                            bankCode: "",
                            branchCode: "",
                            currency: "GBP",
                            iban: "",
                        });
                    } else {
                        this.setState({
                            accNumber: "",
                            bankCode: "",
                            branchCode: "",
                            currency: "EUR",
                            iban: "",
                        });
                    }
                }
            }
        );
    }

    toggleSummary(event) {
        event.preventDefault();
        if (this.state.authorised) {
            this.setState({
                showError: false,
                errorText: "",
                showSummary: !this.state.showSummary
            });
        }
    }

    toggleAuthorised(event) {
        event.preventDefault();
        var newValue = event.target.checked;
        this.setState({
            showError: false,
            errorText: "",
            authorised: newValue
        });
    }

    async createBankAccount(event) {
        event.preventDefault();

        if (!this.state.submitting && this.state.authorised) {
            const { setAuthContext } = this.context;

            var tokenString = await setAuthContext(new Date());
            const config = {
                headers: { Authorisation: `Bearer ` + tokenString },
            };
            this.setState(
                {
                    submitting: true,
                    errorText: "",
                    showError: false
                },
                () => {
                    let data = {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        companyName: this.state.companyName,
                        accNumber: this.state.accNumber,
                        branchCode: this.state.branchCode,
                        address1: this.state.address1,
                        address2: this.state.address2,
                        postcode: this.state.postCode,
                        city: this.state.city,
                        currencyCode: this.state.currency,
                        email: this.state.email,
                        countryCode: this.state.countryCode,
                        iban: this.state.iban,
                        bankCode: this.state.bankCode,
                        OrgId:this.state.orgId
                    };
                    Axios.post(API_ROUTE + Routes.ADD_BANK_ACCOUNT, data, config)
                        .then((response) => {
                            let responseData = response.data;
                            this.setState(
                                {
                                    submitting: false,
                                    mandateSetUp: true
                                });
                        })
                        .catch((reason) => {
                            this.setState({
                                submitting: false,
                                showError: true,
                                errorText: reason.response.data
                            });
                        });
                }
            );
        }
    }

    render() {
        let errorMessage = null;
        if (this.state.showError) {
            errorMessage = <React.Fragment><div><b>{this.state.errorText}</b></div><br /></React.Fragment>
        }
        let accNumberVisible = false;
        let bankCodeVisibile = false;
        let branchCodeVisible = false;
        let bankCodeVisible = false;
        let ibanVisible = false;
        if (this.state.countryCode === "IBAN") {
            ibanVisible = true;
        } else if (
            this.state.countryCode === "GB" ||
            this.state.countryCode === "IE" ||
            this.state.countryCode === "MT"
        ) {
            accNumberVisible = true;
            branchCodeVisible = true;
        } else if (
            this.state.countryCode === "AT" ||
            this.state.countryCode === "DK" ||
            this.state.countryCode === "FI" ||
            this.state.countryCode === "DE" ||
            this.state.countryCode === "LV" ||
            this.state.countryCode === "LT" ||
            this.state.countryCode === "LU" ||
            this.state.countryCode === "NL" ||
            this.state.countryCode === "SK" ||
            this.state.countryCode === "SI"
        ) {
            accNumberVisible = true;
            bankCodeVisible = true;
        } else if (
            this.state.countryCode === "CY" ||
            this.state.countryCode === "FR" ||
            this.state.countryCode === "GR" ||
            this.state.countryCode === "IT" ||
            this.state.countryCode === "MC" ||
            this.state.countryCode === "PT" ||
            this.state.countryCode === "SM" ||
            this.state.countryCode === "ES"
        ) {
            accNumberVisible = true;
            bankCodeVisibile = true;
            branchCodeVisible = true;
        } else if (
            this.state.countryCode === "BE" ||
            this.state.countryCode === "EE"
        ) {
            accNumberVisible = true;
        }
        let toggleText = "Click here to use a company name";
        if (this.state.usingCompanyName) {
            toggleText = "Click here to use a personal name";
        }
        if (this.state.mandateSetUp) {
            return (
                <React.Fragment>
                    {errorMessage}
                    <Row><Col>A Direct Debit mandate has been set up on the account you specified.</Col></Row>
                    <Row><Col>The name on your bank statement will be Virtual DCS.</Col></Row>
                    <Row><Col>You will receive an email within 3 business days confirming the Direct Debit mandate has been set up.</Col></Row>
                    <Row><Col>You may cancel this Direct Debit at any time by contacting Virtual DCS or your bank.</Col></Row>
                    <br />
                    <Row><Col>
                        <Button onClick={(event) => { event.preventDefault(); window.parent.location.reload(); }}>Ok</Button>
                    </Col></Row>
                </React.Fragment>);
        }
        else if (this.state.showSummary) {
            let firstNameLine = <Row><Col><b>First Name: </b></Col><Col>{this.state.firstName}</Col></Row>;
            let lastNameLine = <Row><Col><b>Last Name: </b></Col><Col>{this.state.lastName}</Col></Row>;
            let address2String;
            if (this.state.address2 != "") {
                address2String = <React.Fragment>{this.state.address2}<br /></React.Fragment>
            }
            let addressLine = <Row><Col><b>Address: </b></Col><Col>{this.state.address1}<br />{address2String}</Col></Row>;
            let cityLine = <Row><Col><b>City: </b></Col><Col>{this.state.city}</Col></Row>;
            let postCodeLine = <Row><Col><b>Postcode: </b></Col><Col>{this.state.postCode}</Col></Row>
            let accCodeLine;
            if (accNumberVisible) {
                accCodeLine = <Row><Col><b>Account Number: </b></Col><Col>{this.state.accNumber}</Col></Row>
            }
            let bankCodeLine;
            if (bankCodeVisibile) {
                bankCodeLine = <Row><Col><b>Bank Code: </b></Col><Col>{this.state.bankCode}</Col></Row>
            }
            let branchCodeLine;
            if (branchCodeVisible) {
                branchCodeLine = <Row><Col><b>Branch/Sort Code: </b></Col><Col>{this.state.branchCode}</Col></Row>
            }
            let bankDetailsSection = (
                <div>
                    {accCodeLine}
                    {bankCodeLine}
                    {branchCodeLine}
                </div>);
            return (
                <React.Fragment>
                    {errorMessage}
                    <div><b>Please confirm these details are correct.</b></div>
                    <Button onClick={this.toggleSummary}>Edit</Button>
                    {firstNameLine}
                    {lastNameLine}
                    {addressLine}
                    {cityLine}
                    {postCodeLine}
                    {bankDetailsSection}
                    <Button onClick={this.createBankAccount}>Submit</Button>
                    <div><b>You may cancel this Direct Debit at any time by contacting Virtual DCS or your bank.</b></div>
                </React.Fragment>
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    {errorMessage}
                    <Row>
                        <p>Please complete Direct Debit mandate below.</p>
                    </Row>
                    <Row>
                        <div className="col-4">
                            <InputGroup>
                                <InputGroupAddon style={{ width: "80px" }} addonType="prepend">
                                    <InputGroupText style={{ width: "80px" }}>Country</InputGroupText>
                                </InputGroupAddon>
                                <Dropdown
                                    isOpen={this.state.countrySelectOpen}
                                    toggle={this.toggleCountrySelect}
                                    onChange={this.handleInput}
                                    name="countryCode"
                                >
                                    <DropdownToggle caret>{this.state.countryCode}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="GB"
                                        >
                                            UK
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="IBAN"
                                        >
                                            IBAN
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="AT"
                                        >
                                            AUSTRIA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="BE"
                                        >
                                            BELGIUM
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="CY"
                                        >
                                            CYPRUS
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="DK"
                                        >
                                            DENMARK
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="EE"
                                        >
                                            ESTONIA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="FI"
                                        >
                                            FINLAND
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="FR"
                                        >
                                            FRANCE
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="DE"
                                        >
                                            GERMANY
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="GR"
                                        >
                                            GREECE
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="IE"
                                        >
                                            IRELAND
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="IT"
                                        >
                                            ITALY
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="LV"
                                        >
                                            LATVIA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="LT"
                                        >
                                            LITHUANIA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="LU"
                                        >
                                            LUXEMBOURG
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="MT"
                                        >
                                            MALTA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="MC"
                                        >
                                            MONACO
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="NL"
                                        >
                                            NETHERLANDS
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="PT"
                                        >
                                            PORTUGAL
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="SM"
                                        >
                                            SAN MARINO
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="SK"
                                        >
                                            SLOVAK REPUBLIC
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="SI"
                                        >
                                            SLOVENIA
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="countryCode"
                                            value="ES"
                                        >
                                            SPAIN
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </InputGroup>
                        </div>
                        <div className="col-4">
                            <InputGroup>
                                <InputGroupAddon style={{ width: "90px" }} addonType="prepend">
                                    <InputGroupText style={{ width: "90px" }}>Currency</InputGroupText>
                                </InputGroupAddon>
                                <Dropdown
                                    isOpen={this.state.currencySelectOpen}
                                    toggle={this.toggleCurrencySelect}
                                    onChange={this.handleInput}
                                    name="currency"
                                >
                                    <DropdownToggle caret>{this.state.currency}</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={this.handleInput}
                                            name="currency"
                                            value="GBP"
                                        >
                                            GBP
                                        </DropdownItem>
                                        {/*<DropdownItem
                  onClick={this.handleInput}
                  name="currency"
                  value="EUR"
                >
                  EUR
                </DropdownItem>*/}
                                    </DropdownMenu>
                                </Dropdown>
                            </InputGroup>
                        </div>
                        <div className="col-4">
                            <img style={{ minWidth: "100%" }} src={ddlogo} />
                        </div>
                    </Row>
                    <br />
                    <InputGroup hidden={this.state.usingCompanyName}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                First Name
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="First Name"
                            name="firstName"
                            value={this.state.firstName}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup hidden={this.state.usingCompanyName}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Last Name
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Last Name"
                            name="lastName"
                            value={this.state.lastName}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <InputGroup hidden={!this.state.usingCompanyName}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Company Name
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Company Name"
                            name="companyName"
                            value={this.state.companyName}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <span style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }} onClick={this.toggleCompanyName}>{toggleText}</span>
                    <br />
                    <br />
                    <InputGroup hidden={!ibanVisible}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                IBAN
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="IBAN"
                            name="iban"
                            value={this.state.iban}
                            type="text"
                            onChange={this.handleInput}
                        />
                        <br />
                    </InputGroup>
                    <br hidden={!ibanVisible} />
                    <InputGroup hidden={!accNumberVisible}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Acc Number
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Account Number"
                            name="accNumber"
                            value={this.state.accNumber}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br hidden={!accNumberVisible} />
                    <InputGroup hidden={!branchCodeVisible}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Branch/Sort Code
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Branch/Sort Code"
                            name="branchCode"
                            value={this.state.branchCode}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br hidden={!branchCodeVisible} />
                    <InputGroup hidden={!bankCodeVisible}>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Bank Code
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Bank Code"
                            name="bankCode"
                            value={this.state.bankCode}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br hidden={!bankCodeVisible} />
                    <InputGroup>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Address Line 1
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Address Line 1"
                            name="address1"
                            value={this.state.address1}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Address Line 2
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Address Line 2"
                            name="address2"
                            value={this.state.address2}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>City</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="City"
                            name="city"
                            value={this.state.city}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Post Code
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Post Code"
                            name="postCode"
                            value={this.state.postCode}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <InputGroup>
                        <InputGroupAddon style={{ width: "150px" }} addonType="prepend">
                            <InputGroupText style={{ width: "150px" }}>
                                Email
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            type="text"
                            onChange={this.handleInput}
                        />
                    </InputGroup>
                    <br />
                    <div>
                        I confirm that I am the account holder and am authorised to set up Direct Debit payments on this account.<input type="checkbox" name="authorised" checked={this.state.authorised} onChange={this.handleInput} />
                    </div>
                    <Button onClick={this.toggleSummary}>Add</Button>
                </div>
            );
        }
    }
}
