import React, { Component, createContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import JobList from "./JobList";
import JobHistory from "./JobHistory";
import JobDetail from "./JobDetail";
import Repositories from "./Repositories";
import Eventlogs from "./Eventlogs";

import Overview from "./Overview";
import Axios from "axios";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { NavBar } from "../NavBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import decryptParameter from "../../Helpers/DeCryptParameter";
import AuthenticationContext from "../../Store/Authentication-Context";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Arrow from '../../images/Arrow.png'
LoadingOverlay.propTypes = undefined;
export class MainComp extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);

        this.state = {
            orgId: urlParams.get("orgId") ,
            orgName: urlParams.get("orgName"),
            token: "",
            jobSessionUrl: "",
            jobLogUrl: "",
            org: [],
            repositories: [],
            jobsUrl: "",
            baseUrl: Urls.SERVER_PATH,
            refresh: true,
            isLoading: false,
            priceModal: {},
            totalUserCount: "",
            showErrorPage: false,
            effectiveDate: "",
            isAzureAPIloaded: false
        };
        this.initialLoad = this.initialLoad.bind(this);
        this.reloadRepository = this.reloadRepository.bind(this);
       
    }
   
    openModal = () => this.setState({
        isOpen
            : true
    });
    closeModal = () => this.setState({ isOpen: false });

    componentDidMount() {
        this.initialLoad();
    }

    async initialLoad(event) {
        if (event) {
            event.preventDefault();
        }
        //var token = await loginAuthProvider.getAccessToken();
        //var tokenString = token.accessToken;
        const { setAuthContext } = this.context;

        var tokenString = await setAuthContext(new Date());
        this.setState({ token: tokenString, });
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        //let payload = {
        //    url: "/v7/Organizations/" + this.state.orgId + "/Users",
        //    orgId: this.state.orgId,
        //};
        //const dataNew = await Axios.get(API_ROUTE + Routes.TENANT_USER_COUNT + "/" + this.state.orgId, config)
        //    .then((response) => {

        //        if (response.data)
        //            this.setState({ totalUserCount: response.data })
        //        return JSON.parse(response.data);
        //    })
        //    .catch((reason) => {
        //        //closeAlertModal(reason.message);
        //        //params.hideOverlay();
        //    });

        let data = await Axios.get(API_ROUTE + Routes.GET_ORG + "?orgId=" + this.state.orgId, config)
            .then((response) => {

                let selectItems = JSON.parse(response.data);
                return selectItems;
            })
            .catch((reason) => {
                console.log("catch Org" + reason);
                this.setState(
                    {
                        showErrorPage: true,
                    }
                );
            });
        if (data) {
            this.setState(
                {
                    org: data,
                }
            );
        }
        const { setRepoData, checkDataForId, RepoData } = this.context;
        const isRepoAvailable = await checkDataForId(this.state.orgId);
        let repoData;
        if (isRepoAvailable) {
            repoData=RepoData[this.state.orgId];
        }
        else {
            repoData = await Axios.get(API_ROUTE + Routes.GET_REPO + "?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName + "&baseUrl=" + this.state.baseUrl, config)
                .then((response) => {
                    let selectItems = JSON.parse(response.data);
                    setRepoData({ id: this.state.orgId, data: selectItems });
                    return selectItems;

                })
                .catch((reason) => {
                    console.log("catch Repo ; " + reason);
                    this.setState(
                        {
                            showErrorPage: true,
                        }
                    );
                });
        }
        if (repoData) {
            this.setState(
                {
                    repositories: repoData,
                }
            );
        }
       
        let effectiveData = {
            OrgId: this.state.orgId,
            Setting: "EffectiveDate",
        };
   
        Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, effectiveData, config)
            .then((response) => {
       
                if (response.data.result)
                    this.setState({ effectiveDate: response.data.result });

            })
            .catch((reason) => {

            });

        let priceingModalData = await Axios.get(API_ROUTE + Routes.PRICING_MODEL_ID + "/" + this.state.orgId + "/" + this.state.orgName, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                return selectItems;

            })
            .catch((reason) => {
                console.log("catch Repo ; " + reason);
                this.setState(
                    {
                        showErrorPage: true,
                    }
                );
            });
        if (priceingModalData) {
            this.setState(
                {
                    priceModal: priceingModalData,
                    isAzureAPIloaded: true
                }
            );
        }
    }

    async reloadRepository() {
        //var token = await loginAuthProvider.getAccessToken();
        //var tokenString = token.accessToken;
        const { setAuthContext } = this.context;

        var tokenString = await setAuthContext(new Date());
        this.setState({ token: tokenString, });
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        console.log("Call 2")
        let repoData = await Axios.get(API_ROUTE + Routes.GET_REPO + "?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName + "&baseUrl=" + this.state.baseUrl, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                return selectItems;

            })
            .catch((reason) => {
                console.log("catch Repo ; " + reason);
            });
        if (repoData) {
            this.setState(
                {
                    repositories: repoData,
                }
            );
        }
       
     
    }
    handleGoBack = () => {
        //window.history.back(); 
        this.handleErrorShow();
    };

    handleErrorClose = () => this.setState(
        {
            showErrorPage: false,
        }
    );
    handleErrorShow = () => this.setState(
        {
            showErrorPage: true,
        }
    );
    render() {
        const jobSessionSelected = (url) => {
            this.setState({ jobLogUrl: url });
        }

        const setIsLoading = (params) => {
            this.setState({ isLoading: params });
        };

        const reloadStateRepository = (params) => {
            this.reloadRepository();
            
        };

        const jobSelected = (url) => {
            this.setState({ refresh: "" });
            this.setState({ jobSessionUrl: url });
            this.setState({ jobLogUrl: "" });
        }

        const refreshData = () => {
            this.setState(refreshState => ({ refresh: !refreshState.refresh }));
        }

        let mainSection = (
            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text="Loading your content..."
            >
                {
                    <div>
                        {this.state.showErrorPage &&
                            <>
                            <Modal show={this.state.showErrorPage} onHide={this.handleErrorClose} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Error</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Oops! Something went wrong.<br></br>

                                    Please contact our support team for assistance.<br></br>

                                </Modal.Body>
                                
                            </Modal>
                            <img className="neonArrow" style={{ position: 'fixed', zIndex: '10002', width: '9%', bottom: '0vh', right: '10vw' }} src={Arrow} />
                        </>
                        }
                            
                        <NavBar page="main" authContext={this.context} />
                        <div className="row p-0 me-1" style={{ maxHeight: '92vh', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="col-md-12 col-xl-8 ms-2 pe-1 mb-2">
                                <JobList jobsUrl={(this.state.org.length < 1) ? "" : this.state.org._links.jobs.href} orgDetails={this.state.org} token={this.state.token} jobSelected={jobSelected} refresh={refreshData} repositories={this.state.repositories} isLoading={setIsLoading} reloadRepositories={reloadStateRepository} isAzureAPIload={(this.state.isAzureAPIloaded) ? this.state.priceModal : false} priceModel={(this.state.priceModal) ? this.state.priceModal : "{}"} actualOrgUserCount={(this.state.repositories.OrgUserCount) ? this.state.repositories.OrgUserCount : "0"} />
                                <JobHistory jobSessionUrl={this.state.jobSessionUrl} token={this.state.token} jobSessionSelected={jobSessionSelected} refresh={this.state.refresh} />
                                <JobDetail jobLogUrl={this.state.jobLogUrl} token={this.state.token} refresh={this.state.refresh} />
                            </div>
                            <div className="col col-sm ps-1 pe-0">
                                <Overview org={this.state.org} orgName={this.state.orgName} diskSpace={(this.state.repositories.TotalSpace) ? this.state.repositories.TotalSpace : ""} userCount={(this.state.repositories.UserCount) ? this.state.repositories.UserCount : ""} priceModel={(this.state.priceModal) ? this.state.priceModal : {}} actualOrgUserCount={(this.state.repositories.OrgUserCount) ? this.state.repositories.OrgUserCount : ""} orgUserCount={this.state.totalUserCount} repositories={(this.state.repositories.Repositories) ? this.state.repositories.Repositories : {}} effectiveDate={this.state.effectiveDate} />
                                <Repositories repositories={this.state.repositories} baseUrl={this.state.baseUrl} orgId={this.state.orgId} orgName={this.state.org.name} priceModel={(this.state.priceModal) ? this.state.priceModal : "{}"} />
                                <Eventlogs orgId={this.state.orgId} />
                            </div>
                        </div>
                            
                        
                    </div>
                }
                </LoadingOverlay>

        );
        return (
            <React.Fragment>
                {mainSection}
            </React.Fragment>

        );
    }
}