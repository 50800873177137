import React, { Component } from "react";
const GeneralTheme = React.lazy(() => import('./ThemeObjects/GeneralTheme'));
export class ThemeSelector extends Component {
  render() {
    let generalTheme = <GeneralTheme/>;
    let url = window.location.hostname;
    let chosenTheme;
    if(!url.includes("localhost") && !url.includes("365demo.virtualdcs.co.uk")&& !url.includes("demo.cloudcover.services") && !url.includes("cloudcover365.virtualdcs.co.uk") && !url.includes("preview")){
      const ResellerTheme = React.lazy(() => import('./ThemeObjects/' + url));
      chosenTheme = <ResellerTheme/>
    } else {
      chosenTheme = generalTheme;
    }  
    return (
    <React.Fragment>
      <React.Suspense fallback={<></>}>
        {chosenTheme}
      </React.Suspense>
      {this.props.children}
    </React.Fragment>
  )    
  }
}
