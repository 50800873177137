import React, { Component } from 'react';

export class Warning extends Component {
    render() {
        if (this.props.toShow) {
            return (
                <div>
                    <strong>{this.props.header}</strong> {this.props.text}
                </div>
            );
        }
        return null;
    }
}