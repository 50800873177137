import React, { useState, useEffect } from "react";
//import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import "../../CSS/general.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Placeholder from 'react-bootstrap/Placeholder';
import { InfoCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import HelpModal from "./HelpMessagePopup";

const Overview = ({ org, orgName, userCount, diskSpace, priceModel, orgUserCount, repositories, actualOrgUserCount, effectiveDate }) => {
    const [countUniqueLocations, setCountUniqueLocations] = useState([]);
    const diskSpaceCalculation = (userCount, diskSpace, planModelSpace) => {
        if (!isNaN(parseInt(planModelSpace))) {
            if (planModelSpace !== "0" && planModelSpace !== "") {
                const totalspace = parseInt(planModelSpace);
                const usedPercentage = (Math.round(diskSpace * 100) / 100).toFixed(2) / (totalspace * userCount) * 100;
                if (usedPercentage > 95) {
                    return <> <span id="ContentPlaceHolder1_lblOrgDiskSpace" style={{ color: 'red' }}> {(Math.round(diskSpace * 100) / 100).toFixed(2) + "/" + (totalspace * userCount) + "GB"}</span>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-diskspace">If you exceed your quota additional charges will apply at 0.05 per GB</Tooltip>}>
                            <InfoCircleFill />
                        </OverlayTrigger>
                    </>
                }
                else {
                    return <span id="ContentPlaceHolder1_lblOrgDiskSpace"> {(Math.round(diskSpace * 100) / 100).toFixed(2) + "/" + (totalspace * userCount) + "GB"}</span>
                }
            }
            else {
                return <span id="ContentPlaceHolder1_lblOrgDiskSpace"> {(Math.round(diskSpace * 100) / 100).toFixed(2)}</span>
            }
        }
        else {
            return <span id="ContentPlaceHolder1_lblOrgDiskSpace"> {(Math.round(diskSpace * 100) / 100).toFixed(2)}</span>
        }
    }
    const RenderLocation = (repositories) => {
        if (JSON.stringify(repositories) !== '{}') { 
        if (repositories && repositories.filter(item => item.objectstoragerepositories && item.objectstoragerepositories.amazonBucketS3Compatible && item.objectstoragerepositories.amazonBucketS3Compatible.id !== 0).length !== 0) {
            repositories.map((repo, index) => {
                if (repo.objectstoragerepositories.amazonBucketS3Compatible.id !== 0 && !countUniqueLocations.includes(`(${repo.objectstoragerepositories.amazonBucketS3Compatible.id}) ` + repo.objectstoragerepositories.amazonBucketS3Compatible.Location)) {
                    setCountUniqueLocations([...countUniqueLocations, `(${repo.objectstoragerepositories.amazonBucketS3Compatible.id}) ` + repo.objectstoragerepositories.amazonBucketS3Compatible.Location])
                }
            })
        }
    }
    }
    useEffect(() => {
        RenderLocation(repositories)
    }, [repositories]);
    const [helpPopup, setHelpPopup] = useState(true);
    function closeModal(params) {
        setHelpPopup(params);
    }
    const uniqueLocations = new Set();
    return (<div className="card mt-1" id="overview" style={{ cursor: "default" }}>
        <div className="card-header p-1 d-flex align-items-center">
            <span className="SectionTitle ms-2" style={{ fontWeight: '500', width: '90%' }}>Overview - {orgName}</span>
            <div className="d-flex justify-content-end " style={{ width: '10%' }}>
                <a id="ContentPlaceHolder1_btnHelp" onClick={() => closeModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 384 512" className="mb-1 me-1">
                            <g >
                                <path fill="currentColor" d="M182.4,373.5c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3c38.3,0,69.3-31,69.3-69.3 C251.7,404.5,220.7,373.5,182.4,373.5z"></path><path fill="currentColor" d="M367.9,153.6c0,116-125.3,117.8-125.3,160.6v5.8c0,13.3-10.7,24-24,24h-72.5c-13.3,0-24-10.7-24-24v-9.8 c0-61.8,46.9-86.5,82.3-106.4c30.4-17,49-28.6,49-51.2c0-29.8-38-49.6-68.8-49.6c-39.1,0-57.8,18.1-82.8,49.4 c-8.1,10.2-22.9,12-33.3,4.1l-43.1-32.7c-10.3-7.8-12.6-22.3-5.2-32.9C60.9,32.7,112.6,0,192.4,0C277.3,0,367.9,66.3,367.9,153.6z">
                                </path>
                            </g>
                        </svg> </a>
            </div>
        </div>
        <div className="card-body p-3">
            <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Introduction.html" />
            {(userCount !== "" && priceModel) ? Object.keys(priceModel).length !== 0 ? (
                <div className="row pt-1 pb-1">
                    <div className="col-12 pe-0">
                        <b>Tenant Id:</b> <span id="ContentPlaceHolder1_lblOrganisationName">{(org.name) ? org.name : ""}</span><br />
                        <b>Oldest Restore Point:</b>  <span id="ContentPlaceHolder1_lblFirstBackup">{(org.firstBackuptime) ? moment(org.firstBackuptime).format("DD-MM-YYYY HH:mm:ss").toString() : ""}</span><br />
                    </div>
                    <div className="col-6 pe-0">
                        <b>Package:</b>  <span id="ContentPlaceHolder1_lblPriceModel">{priceModel ? (Object.keys(priceModel).length !== 0) ? priceModel.price.name : "N/A" : "N/A"}</span><br />
                        <b>Disk Space Used (GB): </b>{diskSpaceCalculation(userCount, diskSpace, priceModel ? (Object.keys(priceModel)?.length !== 0 && priceModel.features.find(feature => feature.featureType === "QUOTA").length !== 0) ? priceModel.features.find(feature => feature.featureType === "QUOTA").featureValue : "" : "")}<br />
                        {/*<b>Oldest Restore Point:</b>  <span id="ContentPlaceHolder1_lblFirstBackup">{(org.firstBackuptime) ? moment(org.firstBackuptime).format("DD-MM-YYYY HH:mm:ss").toString() : ""}</span><br/>*/}
                        <b>Primary Copy:</b>
                        {priceModel ? (Object.keys(priceModel).length !== 0 && priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").length !== 0 && priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").featureValue !== "") ? (<>  <span id="ContentPlaceHolder1_lblLocalImmutability">{priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").featureValue + ' ' + priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").featureUnit} </span> <br /></>) : (<>  <span id="ContentPlaceHolder1_lblLocalImmutability">30 Days</span> <br /></>) : (<>  <span id="ContentPlaceHolder1_lblLocalImmutability">30 Days</span> <br /></>)}

                    </div>
                    <div className="col-6">
                        {/*<b>Org User Count:</b>  <span id="ContentPlaceHolder1_lblOrgUserCount">{actualOrgUserCount ? actualOrgUserCount : "N/A"}</span><br />*/}
                        <b>Effective Date:</b> <span id="ContentPlaceHolder1_lblEffectiveDate">{moment(effectiveDate, 'DD/MM/YYYY').format("DD-MM-YYYY").toString()}</span><br />
                        <b>User Count:</b> <span id="ContentPlaceHolder1_lblUserCount">{userCount}{orgUserCount !== "" ? ` / ${orgUserCount}` : ""}</span><br />
                        <b>Secondary Copy: </b>
                        {priceModel ?
                            (Object.keys(priceModel).length !== 0 && priceModel.features.find(feature => feature.featureType === "OLOCKIMMUT").length !== 0 && priceModel.features.find(feature => feature.featureType === "OLOCKIMMUT").featureValue && priceModel.features.find(feature => feature.featureType === "OLOCKIMMUT").featureValue !== "")
                                ? (<> <span id="ContentPlaceHolder1_lblSecondCopyImmutability">{priceModel.features.find(feature => feature.featureType === "OLOCKIMMUT").featureValue + ' ' + priceModel.features.find(feature => feature.featureType === "OLOCKIMMUT").featureUnit} </span></>)
                                : <OverlayTrigger overlay={<Tooltip id="tooltip-diskspace">Not available in your package</Tooltip>}>
                                    <span>N/A</span>
                                </OverlayTrigger>
                            : <OverlayTrigger overlay={<Tooltip id="tooltip-diskspace">Not available in your package</Tooltip>}>
                                <span>N/A</span>
                            </OverlayTrigger>}
                    </div>
                    <div className="col-12 pe-0 d-flex">
                        <b className="me-2">Location:</b>
                        {repositories && repositories.filter(item => item.objectstoragerepositories && item.objectstoragerepositories.amazonBucketS3Compatible && item.objectstoragerepositories.amazonBucketS3Compatible.id !== 0).length === 0
                            ? <span> ---- </span>
                            :
                            countUniqueLocations.length > 4 ?
                                <OverlayTrigger overlay={<Tooltip>{Array.from(countUniqueLocations).join(' ')}</Tooltip>}>
                                    <div className="col-12 pe-0 d-flex">
                                        {repositories.map((repo, index) => {
                                            if (repo.objectstoragerepositories.amazonBucketS3Compatible.id !== 0 && !uniqueLocations.has(repo.objectstoragerepositories.amazonBucketS3Compatible.Location)) {
                                                uniqueLocations.add(repo.objectstoragerepositories.amazonBucketS3Compatible.Location);
                                                return (<div key={repo.objectstoragerepositories.amazonBucketS3Compatible.id}>
                                                    <span className="font1rem me-2" key={`span_${index}_${repo.objectstoragerepositories.amazonBucketS3Compatible.id}`}>({repo.objectstoragerepositories.amazonBucketS3Compatible.id}) {repo.objectstoragerepositories.amazonBucketS3Compatible.Location}</span></div>
                                                );
                                            }
                                            else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </OverlayTrigger> :
                                <div className="col-12 pe-0 d-flex">
                                    {repositories.map((repo, index) => {
                                        if (repo.objectstoragerepositories.amazonBucketS3Compatible.id !== 0 && !uniqueLocations.has(repo.objectstoragerepositories.amazonBucketS3Compatible.Location)) {
                                            uniqueLocations.add(repo.objectstoragerepositories.amazonBucketS3Compatible.Location);
                                            if (uniqueLocations.size < 4) {
                                                return (<div key={repo.objectstoragerepositories.amazonBucketS3Compatible.id}>
                                                    <span className="font1rem me-2" key={`span_${index}_${repo.objectstoragerepositories.amazonBucketS3Compatible.id}`}>({repo.objectstoragerepositories.amazonBucketS3Compatible.id}) {repo.objectstoragerepositories.amazonBucketS3Compatible.Location}</span></div>
                                                );
                                            }
                                            else {
                                                return null;
                                            }
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                </div>


                        }
                        <br />
                    </div>
                </div>
            ): (
                    <div className="row pt-1 pb-1" >
                        <div className="col-12">
                            <Placeholder animation="wave">
                                <Placeholder xs={3} /> <Placeholder xs={5} />
                                <Placeholder xs={4} /> <Placeholder xs={5} />
                            </Placeholder>
                        </div>
                        <div className="col-6">

                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={3} /> <Placeholder xs={4} />
                                </Placeholder>
                            </div>
                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={7} /> <Placeholder xs={3} />
                                </Placeholder>
                            </div>
                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={4} /> <Placeholder xs={3} />
                                </Placeholder>
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={4} /> <Placeholder xs={3} />
                                </Placeholder>
                            </div>
                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={4} /> <Placeholder xs={3} />
                                </Placeholder>
                            </div>
                            <div className="col-12">
                                <Placeholder animation="wave">
                                    <Placeholder xs={4} /> <Placeholder xs={5} />
                                </Placeholder>
                            </div>
                        </div>
                        <div className="col-12">
                            <Placeholder animation="wave">
                                <Placeholder xs={3} /> <Placeholder xs={5} />
                            </Placeholder>
                        </div>
                    </div>
            ) : (<div className="row pt-1 pb-1" >
                <div className="col-12">
                    <Placeholder animation="wave">
                        <Placeholder xs={3} /> <Placeholder xs={5} />
                        <Placeholder xs={4} /> <Placeholder xs={5} />
                    </Placeholder>
                </div>
                <div className="col-6">

                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={3} /> <Placeholder xs={4} />
                        </Placeholder>
                    </div>
                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={7} /> <Placeholder xs={3} />
                        </Placeholder>
                    </div>
                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={4} /> <Placeholder xs={3} />
                        </Placeholder>
                    </div>

                </div>
                <div className="col-6">
                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={4} /> <Placeholder xs={3} />
                        </Placeholder>
                    </div>
                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={4} /> <Placeholder xs={3} />
                        </Placeholder>
                    </div>
                    <div className="col-12">
                        <Placeholder animation="wave">
                            <Placeholder xs={4} /> <Placeholder xs={5} />
                        </Placeholder>
                    </div>
                </div>
                <div className="col-12">
                    <Placeholder animation="wave">
                        <Placeholder xs={3} /> <Placeholder xs={5} />
                    </Placeholder>
                </div>
            </div>)
            }

        </div>
    </div>
    );
}

export default Overview;