import React, { Component } from "react";
import { authProvider } from "../AuthProvider";
import Axios from "axios";
import { API_ROUTE, LEGACY_SITE, Routes } from "../Helpers/Constants";
import { Button } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import Spinner from "reactstrap/lib/Spinner";
import LoaderGif from "../images/365 loading.gif"

export class BackupAccountProgress extends Component {
    constructor(props) {
        super(props);
        let page = false;
        if (props.page) {
            page = props.page;
        }
        var orgId = props.orgId;
        var orgName = props.orgName;
        this.state = {
          count: 0,
          add: 1,
          isMfa: false,
          error: false,
          checkedMFA: false,
          inProgress: false,
          done: false,
          deviceCode: "",
          hasDeviceCode: false,
          errorMessage: "",
          page: page,
          orgId: orgId,
          orgName: orgName
        };
        this.workerAccountsStep = this.workerAccountsStep.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.changeAdd = this.changeAdd.bind(this);
        this.getDeviceCode = this.getDeviceCode.bind(this);
        this.addApplications = this.addApplications.bind(this);
    }
  static displayName = BackupAccountProgress.name;

  removeNavBar() {
    if(!this.state.page){
      var oldLinks = document.head.childNodes;
      var navBarLinks = document.body.getElementsByClassName("row titlebar custStyle");
      for(var i = 0; i < navBarLinks.length; i++){
        var link = navBarLinks[i];
        link.parentNode.removeChild(link);
      }
    }    
  }

  async componentDidMount(){
    this.removeNavBar();
    await this.getInfo();
  }

  async getInfo(){
    var token = await authProvider.getAccessToken();
    let tokenString = token.accessToken;
    this.setState({
      count: 0,
      add: 1,
      isMfa: false,
      error: false,
      checkedMFA: false,
      inProgress: false,
      done: false,
      deviceCode: "",
      hasDeviceCode: false,
      errorMessage: ""
    },()=>{
      const config = {
        headers: {
          Authorisation: `Bearer ` + tokenString
        },
      };
        Axios.get(API_ROUTE + Routes.GET_WORKER_ACCOUNT_INFO + "/" + this.state.orgId, config)
        .then((response) => {
          var info = response.data;
          this.setState({
            checkedMFA: true,
            inProgress: false,
            isMfa: info.isMfa,
            count: info.count
          })
        })
        .catch((reason) => {
          let message = "";
          if(reason){
            if(reason.response){
              if(reason.response.data){
                message = reason.response.data;
              }
            }
          }        
          this.setState(
            {
              errorMessage: message,
              inProgress: false,
              checkedMFA: false,
              error: true
            });
        });
    });    
  }

  async workerAccountsStep() {
    var token = await authProvider.getAccessToken();
    let tokenString = token.accessToken;    
    if(!this.state.isMfa && this.state.add <= 20 && this.state.add >= 1){
      this.setState({
        inProgress: true
      },()=>{
        const config = {
          headers: {
            Authorisation: `Bearer ` + tokenString
          },
        };
        let createValue = this.state.add;
        Axios.get(API_ROUTE + Routes.ADD_WORKER_ACCOUNTS + "/" + createValue.toString(), config)
          .then((response) => {
            this.setState({
              done: true,
              inProgress: false
            })
          })
          .catch((reason) => {
            let message = "";
        if(reason){
          if(reason.response){
            if(reason.response.data){
              message = reason.response.data;
            }
          }
        } 
            this.setState(
              {
                errorMessage: message,
                inProgress: false,
                error: true
              });
          });
      });   
    }    
  }

  async getDeviceCode(){
    var token = await authProvider.getAccessToken();
    let tokenString = token.accessToken;    
    if(this.state.isMfa){
      this.setState({
        inProgress: true
      },()=>{
        const config = {
          headers: {
            Authorisation: `Bearer ` + tokenString
          },
        };
        let data = {
          url: window.location.origin
        };
        Axios.post(API_ROUTE + Routes.GET_DEVICE_CODE, data, config)
          .then((response) => {
            this.setState({
                deviceCode: response.data.message,
              hasDeviceCode: true,
              inProgress: false
            })
          })
          .catch((reason) => {
            let message = "";
        if(reason){
          if(reason.response){
            if(reason.response.data){
              message = reason.response.data;
            }
          }
        } 
            this.setState(
              {
                errorMessage: message,
                inProgress: false,
                error: true
              });
          });
      });   
    } 
  }

  async addApplications() {
    var token = await authProvider.getAccessToken();
    let tokenString = token.accessToken;    
    if(this.state.isMfa && this.state.add <= 20 && this.state.add >= 1){
      this.setState({
        inProgress: true
      },()=>{
        const config = {
          headers: {
            Authorisation: `Bearer ` + tokenString
          },
        };
        let createValue = this.state.add;
        let data = {
          DeviceCode: this.state.deviceCode,
            Count: createValue,
            OrgId: this.state.orgId
        };
        Axios.post(API_ROUTE + Routes.ADD_APPLICATIONS, data, config)
          .then((response) => {
            this.setState({
              done: true,
              hasDeviceCode: false,
              deviceCode: "",
              inProgress: false
            })
          })
          .catch((reason) => {
            let message = "";
        if(reason){
          if(reason.response){
            if(reason.response.data){
              message = reason.response.data;
            }
          }
        } 
            this.setState(
              {
                errorMessage: message,
                inProgress: false,
                error: true
              });
          });
      });   
    }    
  }

  changeAdd(event){
    var value = parseInt(event.target.value);
    if(value <= 20 && value >= 1){
      this.setState({
        add: value
      }); 
    } else {
      this.setState({
        add: this.state.add
      });
    }      
  }

  render() {
    let spinner = null;
    if(this.state.inProgress){
        spinner = <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
            <img src={LoaderGif} alt="loading" style={{ height: '45px' }} />
        </div>
    }
    let nameString = "Accounts";
    if(this.state.isMfa){
      nameString = "Applications";
    }
    if(this.state.error){
      return (
      <div style={{backgroundColor: "white", backgroundImage: "none"}}>
        {spinner}
        <p>Error.</p>
        <p>{this.state.errorMessage}</p>
        <Button onClick={this.getInfo}>Retry</Button>
      </div>);
    } else if(this.state.done){
      return (
        <div style={{backgroundColor: "white", backgroundImage: "none"}}>
          {spinner}
              <p>Backup {nameString} succesfully added.</p>
              <Button onClick={() => { window.location.href = "/settings?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName }}>Okay</Button>

        </div>
      );
    }
      let backButton = (<a href={"/settings?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName} className="btn">Back</a>)
      let button;
      //=(<Button disabled={this.state.inProgress} onClick={this.workerAccountsStep}>Add</Button>);
    if(this.state.isMfa){
      if(this.state.hasDeviceCode){
        button = (<Button disabled={this.state.inProgress} onClick={this.addApplications}>Add</Button>);
        return(
          <div style={{backgroundColor: "white", backgroundImage: "none"}}>
            {spinner}
            <p>Add auxillary backup applications to process SharePoint and OneDrive for Business data. Using applications allows to increase backup performance and avoid throttling.</p>
            <p>Current Additional Backup {nameString}: {this.state.count}</p>
            <b>You must be logged in with a Global admin user for the tenant account you are updating.</b>
            <p>How many {nameString} do you want: <input max={20} min={1} value={this.state.add} type="number" onChange={this.changeAdd}/></p>

            <p>
              Your account must have permissions to authenticate to the Office
              365 organisation.
              <br />
              To sign in, authenticate with the code below at:{" "}
              <a href="https://microsoft.com/devicelogin" target="_blank">
                https://microsoft.com/devicelogin
              </a>
              <br />
              <br />
              Please copy below code to clipboard before following above URL.
              <br/>
              <div>
                <input type="text" value={this.state.deviceCode} disabled />
                        <CopyToClipboard
                            disabled={this.state.inProgress}
                            text={this.state.deviceCode}
                            onCopy={() => {
                                this.setState({ copied: true });
                                window.open("https://microsoft.com/devicelogin", '_blank', 'noopener,noreferrer');
                            }}>
                            <button style={{ color: "grey" }} className="btn">
                                Copy & Redirect
                            </button>
                </CopyToClipboard>
              </div>
            </p>
            
            {button}
            {backButton}
          </div>
        );
      } else {
        button = (<Button disabled={this.state.inProgress} onClick={this.getDeviceCode}>Add Auxillary Applications</Button>);
        return(
          <div style={{backgroundColor: "white", backgroundImage: "none"}}>
            {spinner}
            <p>Add auxillary backup applications to process SharePoint and OneDrive for Business data. Using applications allows to increase backup performance and avoid throttling.</p>
            <p>Current Additional Backup {nameString}: {this.state.count}</p>
            <b>You must be logged in with a Global admin user for the tenant account you are updating.</b>
            <p>How many {nameString} do you want: <input max={20} min={1} value={this.state.add} type="number" onChange={this.changeAdd}/></p>
            {button}
            {backButton}
          </div>
        );
      }
    }
    if(this.state.inProgress){
      return (
        <div style={{backgroundColor: "white", backgroundImage: "none"}}>
          {spinner}
          <p>In Progress</p>
        </div>
      );
    }
    else if(!this.state.checkedMFA){
      return (
        <div style={{backgroundColor: "white", backgroundImage: "none"}}>
          {spinner}
          <p>Checking status.</p>
        </div>
      );
    } else {
      return (
        <div style={{backgroundColor: "white", backgroundImage: "none"}}>
          {spinner}
          <p>Add auxillary backup accounts to process SharePoint and OneDrive for Business data. Using multiple accounts allows to increase backup performance and avoid throttling.</p>
          <p>Current Additional Backup {nameString}: {this.state.count}</p>
          <b>You must be logged in with a Global admin user for the tenant account you are updating.</b>
          <p>How many {nameString} do you want: <input max={20} min={1} value={this.state.add} type="number" onChange={this.changeAdd}/></p>
          {button}
          {backButton}
        </div>
      );
    }
    
  }
}