import React, { Component } from "react";
export class SubscriberAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <React.Fragment>
                <a href="https://www.virtualdcs.co.uk/download/subscriber-agreement.pdf" target="_blank" style={{ marginRight: "5px" }}>Subscriber Agreement</a>
            </React.Fragment>
        );
    }
}