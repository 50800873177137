import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { API_ROUTE, Routes } from "../Helpers/Constants";
import Axios from "axios";
import AuthenticationContext from '../Store/Authentication-Context';
import LoaderGif from "../images/365 loading.gif";
const RestoreAccessModal = (props) => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const [textValue, setTextValue] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const closeModal = (e) => {
        if (e) e.stopPropagation();
        props.closeModal(false);
        setTimeout(() => {
            setTextValue('');
        }, 0); // Delay state update to avoid synchronous update during rendering
    };

    const onTextChange = (event) => {
        setTextValue(event.target.value);
    };

    async function postRestoreComment(textValue) {
        try {
            setInProgress(true);
            const tokenString = await setAuthContext("", new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                },
            };

            let data = {
                Message: textValue,
                OrgId: props.orgId,
                Type: props.type
            };

            let response = await Axios.post(API_ROUTE + Routes.LOG_RESTORESESSION_Comment, data, config);
            console.log(response.data);
            setTextValue('');
            setInProgress(false);
            props.handleRestoreChallangeSubmitted();
            props.onCommentSubmitted(); // Notify the parent component
        } catch (error) {
            setInProgress(false);
            console.error("Error posting restore comment:", error);
        }
    }


    return (
        <Modal
            show={props.displayModal}
            onHide={closeModal}
            centered
            size="lg"
        >
                <Modal.Header style={{padding :0} }>
                    <Modal.Title id="exampleModalLabel" style={{ textAlign: "left", width: "100%" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
                            <h3 style={{ margin: "10px" }}>Restore Challenge</h3>
                            <button type="button" className="btn-close" aria-label="Close" style={{ transform: 'scale(0.75)', marginRight:"5px" }} onClick={closeModal}></button>
                    </div>
                        
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*<hr style={{ borderTop: "1px solid #2671bfcc", margin: "10px 0" }} />*/}
                <h5 style={{ margin: "10px" }}>Please complete the reason you would like to action a restore session.</h5>
                {inProgress && <div style={{ position: 'absolute', height: '100%', width: '96%', background: 'white', top: '0px', opacity: '0.8' }}>
                    <div className="justify-content-center align-items-center d-flex h-100" >
                        <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                    </div>
                </div>}
                <div style={{ padding: "0px 5px 0px 5px" }}>
                    <Form.Group controlId="restoreReason">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={textValue}
                            onChange={onTextChange}
                            placeholder="Enter text..."
                            style={{
                                borderColor: 'black',
                                borderStyle: 'solid',
                                padding: '10px',
                                fontSize: '16px',
                                height: '170px'
                            }}
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal} >
                        Cancel
                </Button>
                <Button variant="primary" onClick={() => postRestoreComment(textValue)} disabled={!textValue.trim()}>
                        Continue
                    </Button>
                </Modal.Footer>
        </Modal>
    );
};

export default RestoreAccessModal;
