import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../CSS/general.css";
import { Loader, Icon, Dimmer, Image } from "semantic-ui-react";
import { authProvider } from "../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes, LEGACY_SITE } from "../Helpers/Constants";
import {
  Button,
  Table,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap";
import { Warning } from "./Warning";
import still from "../images/setupimages/signup.png";
import microsoft from "../images/setupimages/microsoft.png";
import veeam from "../images/setupimages/veeam.png";
import zadara from "../images/setupimages/zadara.png";
import { ReactTooltip } from "./ReactTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
  faQuestionCircle,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";
import { isGetAccessor } from "typescript";
import { CopyToClipboard } from "react-copy-to-clipboard";

export class RegistrationProgressLegacy extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const adminConsent = urlParams.get("admin_consent");
    let tenantConfirmed = false;
    let consentError = false;
    if (adminConsent) {
      if (adminConsent == "True") {
        tenantConfirmed = true;
      } else {
        consentError = true;
      }
    }
    this.state = {
      email: "",
      consentError: consentError,
      tenantConfirmed: tenantConfirmed,
      inProgress: false,
      isAdmin: false,
      adminCheck: false,
      currentStep: "",
      isRegistered: false,
      isModernAuth: false,
      readyToTakeInput: false,
      forgotPassword: false,
      password: "",
      isTeamsEnabled: false,
      permissionLink: "",
      warningText: "",
      gotProgress: false,
      appSecret: "",
      showSecretModal: false,
      hasSecret: false,
      passwordError: false,
      passwordErrorText: "",
      microsoftError: false,
      microsoftErrorText: "",
      headerText: "",
      inProgressForgot: false,
      notificationEmail: ""
    };
    this.getIsAdmin = this.getIsAdmin.bind(this);
    this.getSetupInfo = this.getSetupInfo.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.createVboUser = this.createVboUser.bind(this);
    this.createApplication = this.createApplication.bind(this);
    this.getAppPermissionLink = this.getAppPermissionLink.bind(this);
    this.createAdminGroup = this.createAdminGroup.bind(this);
    this.addToAdminGroup = this.addToAdminGroup.bind(this);
    this.addToVeeam = this.addToVeeam.bind(this);
    this.logout = this.logout.bind(this);
    this.confirmPermission = this.confirmPermission.bind(this);
    this.getLeftCard = this.getLeftCard.bind(this);
    this.getRightCard = this.getRightCard.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.hideSecretModal = this.hideSecretModal.bind(this);
    this.forgotPasswordButtonClick = this.forgotPasswordButtonClick.bind(this);
    this.getLeftCardButtons = this.getLeftCardButtons.bind(this);
  }
  static displayName = RegistrationProgressLegacy.name;

  componentDidMount() {
    var accountInfo = authProvider.getAccountInfo();
    var email = accountInfo.account.userName;
    this.setState(
      {
        email: email,
        notificationEmail: email
      },
      () => {
        this.getIsAdmin();
      }
    );
  }

  async getIsAdmin() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.GET_IS_ADMIN, config)
          .then((response) => {
            let isAdmin = response.data;
            if (isAdmin) {
              this.setState(
                {
                  isAdmin: isAdmin,
                  adminCheck: true,
                  inProgress: false,
                },
                () => {
                  if (isAdmin) {
                    this.getSetupInfo();
                  }
                }
              );
            } else {
              this.setState({
                isAdmin: isAdmin,
                inProgress: false,
              });
            }
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
              });
            }
          });
      }
    );
  }

  async getSetupInfo() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.GET_SET_UP_INFO, config)
          .then((response) => {
            let info = response.data;
            let modernAuth = info.modernAuth;
            if (modernAuth) {
              window.location = "/SignUpModern";
            }
            let registered = info.registered;
            let step = info.step;
            if (this.state.tenantConfirmed) {
              step = "ADMINGROUPNEEDED";
            } else if (registered) {
              step = "VBOUSERNEEDED";
            }
            let hasSecret = info.hasSecret;
            this.setState(
              {
                inProgress: false,
                hasSecret: hasSecret,
                currentStep: step,
                isModernAuth: modernAuth,
                isRegistered: registered,
                readyToTakeInput: true,
                headerText: "",
                gotProgress: true,
              },
              () => {
                if (this.state.tenantConfirmed) {
                  this.nextStep();
                }
                if(step == "APPPERMISSIONSNEEDED"){
                  this.getAppPermissionLink();
                }
              }
            );
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                    headerText: "",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                  headerText: "",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",

                headerText: "",
              });
            }
          });
      }
    );
  }

  async nextStep(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState(
      {
        warningText: "",
        microsoftError: false,
        microsoftErrorText: "",
        passwordError: false,
        passwordErrorText: "",
        headerText: "",
      },
      () => {
        let currentStep = this.state.currentStep;
        switch (currentStep) {
          case "VBOUSERNEEDED":
            this.createVboUser();
            break;
          case "APPLICATIONNEEDED":
            this.createApplication();
            break;
          case "APPPERMISSIONSNEEDED":
            this.getAppPermissionLink();
            break;
          case "ADMINGROUPNEEDED":
            this.createAdminGroup();
            break;
          case "USERINADMINGROUPNEEDED":
            this.addToAdminGroup();
            break;
          case "VEEAMSTEPNEEDED":
            this.addToVeeam();
            break;
          case "DONE":
            break;
          default:
        }
      }
    );
  }

  async createVboUser() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText: "Waiting for Microsoft to complete request. This could take between 10 and 15 minutes.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        let data = {
          Password: this.state.password,
          TeamsEnabled: this.state.isTeamsEnabled,
          CallDomain: window.location.origin,
          NewUser: this.state.forgotPassword,
          AppSecret: this.state.appSecret,
        };
        Axios.post(API_ROUTE + Routes.CREATE_VBOUSER, data, config)
          .then((response) => {
            this.setState(
              {
                currentStep: "APPLICATIONNEEDED",
                headerText: "",
              },
              () => {
                this.nextStep();
              }
            );
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  if (
                    reason.response.data ==
                    "The specified password does not comply with your organisation's password complexity requirements. Please provide a different password."
                  ) {
                    this.setState({
                      inProgress: false,
                      passwordError: true,
                      passwordErrorText: reason.response.data,
                      headerText: "",
                    });
                  } else {
                    this.setState({
                      inProgress: false,
                      warningText: reason.response.data,
                      headerText: "",
                    });
                  }
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                    headerText: "",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  headerText: "",
                  warningText: "Error. Please contact support.",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                headerText: "",
                warningText: "Error. Please contact support.",
              });
            }
          });
      }
    );
  }

  async createApplication() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText: "Waiting for Microsoft to complete request. This could take between 10 and 15 minutes.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        let data = {
          Password: this.state.password,
          TeamsEnabled: this.state.isTeamsEnabled,
          CallDomain: window.location.origin,
          NewUser: this.state.forgotPassword,
          AppSecret: this.state.appSecret,
        };
        Axios.post(API_ROUTE + Routes.CREATE_BACKUP_APPLICATION, data, config)
          .then((response) => {
            this.setState({
              permissionLink: response.data,
              headerText: "",
              currentStep: "APPPERMISSIONSNEEDED",
              inProgress: false,
            });
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                    headerText: "",
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                    headerText: "",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                  headerText: "",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
                headerText: "",
              });
            }
          });
      }
    );
  }

  async getAppPermissionLink() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText: "Getting permission link. Waiting for Microsoft to complete request.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        let data = {
          Password: this.state.password,
          TeamsEnabled: this.state.isTeamsEnabled,
          CallDomain: window.location.origin,
          NewUser: this.state.forgotPassword,
          AppSecret: this.state.appSecret,
        };
        Axios.post(API_ROUTE + Routes.GET_APP_PERMISSION_LINK, data, config)
          .then((response) => {
            this.setState({
              permissionLink: response.data,
              inProgress: false,
              headerText: ""
            });
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                    headerText: "",
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                    headerText: "",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                  headerText: "",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
                headerText: "",
              });
            }
          });
      }
    );
  }

  async createAdminGroup() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText: "Waiting for Microsoft to complete request.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.CREATE_ADMIN_GROUP, config)
          .then((response) => {
            this.setState(
              {
                currentStep: "USERINADMINGROUPNEEDED",
                headerText: "",
              },
              () => {
                this.nextStep();
              }
            );
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
              });
            }
          });
      }
    );
  }

  async addToAdminGroup() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText: "Waiting for Microsoft to complete request.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.ADD_TO_GROUP, config)
          .then((response) => {
            this.setState({
              currentStep: "VEEAMSTEPNEEDED",
              headerText: "",
              inProgress: false,
            });
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgress: false,
                    warningText: reason.response.data,
                  });
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
              });
            }
          });
      }
    );
  }

  async addToVeeam() {
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgress: true,
        headerText:
          "Waiting for Microsoft to sync credentials to all environments. This could take between 10 and 15 minutes.",
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        let data = {
          Password: this.state.password,
          TeamsEnabled: this.state.isTeamsEnabled,
          CallDomain: window.location.origin,
          NewUser: this.state.forgotPassword,
          AppSecret: this.state.appSecret,
          NotificationEmail: this.state.notificationEmail,
        };
        Axios.post(API_ROUTE + Routes.ADD_TO_VEEAM, data, config)
          .then((response) => {
            this.setState({
              currentStep: "DONE",
              inProgress: false,
              headerText: ""
            });
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  let response = reason.response.data;
                  if (response.hasSecret) {
                    this.setState({
                      appSecret: response.secret,
                      showSecretModal: true,
                      inProgress: false,
                      warningText: response.message,
                    });
                  } else {
                    this.setState({
                      inProgress: false,
                      warningText: response.message,
                    });
                  }
                } else {
                  this.setState({
                    inProgress: false,
                    warningText: "Error. Please contact support.",
                  });
                }
              } else {
                this.setState({
                  inProgress: false,
                  warningText: "Error. Please contact support.",
                });
              }
            } else {
              this.setState({
                inProgress: false,
                warningText: "Error. Please contact support.",
              });
            }
          });
      }
    );
  }

  async forgotPasswordButtonClick(event) {
    event.preventDefault();
    var token = await authProvider.getAccessToken();
    var tokenString = token.accessToken;
    this.setState(
      {
        inProgressForgot: true,
        passwordError: false,
        microsoftError: false,
      },
      () => {
        const config = {
          headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.FORGOT_PASSWORD, config)
          .then((response) => {
            this.setState({
              inProgressForgot: false,
              warningText: "",
              currentStep: "VBOUSERNEEDED",
              forgotPassword: true,
            });
          })
          .catch((reason) => {
            if (reason) {
              if (reason.response) {
                if (reason.response.data) {
                  this.setState({
                    inProgressForgot: false,
                    warningText: reason.response.data,
                  });
                } else {
                  this.setState({
                    inProgressForgot: false,
                    warningText: "Error. Please contact support.",
                    headerText: "",
                  });
                }
              } else {
                this.setState({
                  inProgressForgot: false,
                  warningText: "Error. Please contact support.",
                  headerText: "",
                });
              }
            } else {
              this.setState({
                inProgressForgot: false,
                warningText: "Error. Please contact support.",
                headerText: "",
              });
            }
          });
      }
    );
  }

  logout(event) {
    event.preventDefault();
    authProvider.logout();
    window.location = "/Logout";
  }

  

  confirmPermission(event) {
    event.preventDefault();
    this.setState(
      {
        currentStep: "ADMINGROUPNEEDED",
      },
      () => {
        this.nextStep();
      }
    );
  }

  handleInput(event) {
    var name = event.target.name;
    var value = null;
    if (name == "isTeamsEnabled") {
      var value = event.target.checked;
    } else {
      var value = event.target.value;
    }
    this.setState({
      [name]: value,
    });
  }

  forgotPassword(event) {
    event.preventDefault();
    this.setState({
      password: "",
      currentStep: "VBOUSERNEEDED",
      forgotPassword: true,
    });
  }

  getLeftCard() {
    let currentStep = this.state.currentStep;
    let card = null;
    if (!this.state.gotProgress) {
      card = (
        <React.Fragment>
          <br />
              <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Checking your tenant and set up progress.</p>
        </React.Fragment>
      );
    } else if (this.state.isAdmin) {
      switch (currentStep) {
        case "VBOUSERNEEDED":
          card = (
            <React.Fragment>
              <div className="row mb-2">
              <p>
                To perform a backup we will need to create a service account on
                your tenant, please provide us with a password to use for the VBO
                User we will create on your tenant.
              </p>
              <br />
              <p>
                Please make sure you keep note of the password as you may need
                it again.
              </p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Password</InputGroupText>
                </InputGroupAddon>
                <Input
                  value={this.state.password}
                  name="password"
                  type="password"
                  onChange={this.handleInput}
                />
              </InputGroup>
              </div>              
            </React.Fragment>
          );
          break;
        case "APPLICATIONNEEDED":
          card = (
            <React.Fragment>
            </React.Fragment>
          );
          break;
        case "APPPERMISSIONSNEEDED":
          card = (
            <React.Fragment>
              <div className="row pb-6">
                <p>
                  Please grant permission to the app we have created on your
                  tenant.
                </p>
              </div>
            </React.Fragment>
          );
          break;
        case "ADMINGROUPNEEDED":
          card = (
            <React.Fragment>
            </React.Fragment>
          );
          break;
        case "USERINADMINGROUPNEEDED":
          card = (
            <React.Fragment>
            </React.Fragment>
          );
          break;
        case "VEEAMSTEPNEEDED":
          let secretSection = null;
          if (this.state.hasSecret) {
            secretSection = (
              <React.Fragment>
                <p>
                  If you don't know your application secret, or this is your
                  first time setting up, leave the field blank.
                </p>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Secret</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={this.state.appSecret}
                    name="appSecret"
                    type="password"
                    onChange={this.handleInput}
                  />
                </InputGroup>
                <br />
              </React.Fragment>
            );
          }
          card = (
            <React.Fragment>
              <p>Please re-enter the password you originally created.</p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Password</InputGroupText>
                </InputGroupAddon>
                <Input
                  value={this.state.password}
                  name="password"
                  type="password"
                  onChange={this.handleInput}
                />
              </InputGroup>
              <br/>
              <p>Please amend this address if you cannot receive emails on it. You will receive further instructions via this address when set up is complete.</p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Email</InputGroupText>
                </InputGroupAddon>
                <Input
                  value={this.state.notificationEmail}
                  name="notificationEmail"
                  type="text"
                  onChange={this.handleInput}
                />
              </InputGroup>


              <br />
              {secretSection}
              <div>
                Enable Teams Backup{" "}
                <input
                  type="checkbox"
                  name="isTeamsEnabled"
                  checked={this.state.isTeamsEnabled}
                  onChange={this.handleInput}
                />
                <p style={{ fontSize: "small" }}>
                  (To enable full Teams backup, the VBOAccount must be assigned
                  a Teams license before you complete the next step.)
                </p>
              </div>
            </React.Fragment>
          );
          break;
        case "DONE":
          card = (
            <React.Fragment>
              <br />
              <br />
              You're all set up!
              <br />
              Press Continue to Log In.
            </React.Fragment>
          );
          break;
        default:
      }
    } else {
      if (this.state.adminCheck) {
        card = (
          <React.Fragment>
                <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif", fontSize: '18px' }}>
              You must be a Global Admin on your Tenant to complete this
              process.
            </p>
            <Button
              disabled={this.state.inProgress || this.state.inProgressForgot}
              onClick={this.logout}
            >
              Log Out
            </Button>
          </React.Fragment>
        );
      } else {
        card = (
          <React.Fragment>
            <br />
                <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif", fontSize: '18px' }}>Checking your tenant and set up progress.</p>
          </React.Fragment>
        );
      }
    }

    return card;
  }

  getLeftCardButtons() {
    let currentStep = this.state.currentStep;
    let card = null;
     if (this.state.isAdmin) {
      switch (currentStep) {
        case "VBOUSERNEEDED":
          card = (
            <React.Fragment>              
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.nextStep}
                      color="primary"
                      style={{fontSize: "0.95rem"}}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                  <Button
                    disabled={
                      this.state.inProgress || this.state.inProgressForgot
                    }
                    onClick={this.forgotPasswordButtonClick}
                    style={{fontSize: "0.95rem"}}
                  >
                    Forgot Password
                  </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "APPLICATIONNEEDED":
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.nextStep}
                      color="primary"
                      style={{fontSize: "0.95rem"}}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                  <Button
                    disabled={
                      this.state.inProgress || this.state.inProgressForgot
                    }
                    onClick={this.forgotPasswordButtonClick}
                    style={{fontSize: "0.95rem"}}
                  >
                    Reset
                  </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "APPPERMISSIONSNEEDED":
          let grantButton = (<a
            href={this.state.permissionLink}
            target="_self"
            className="btn btn-primary"
            style={{fontSize: "0.95rem"}}
          >
            GRANT
          </a>);
          if(this.state.inProgress || this.state.permissionLink == ""){
            grantButton = <Button disabled={true}>GRANT</Button>
          }
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                  {grantButton}
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                  <Button
                    disabled={
                      this.state.inProgress || this.state.inProgressForgot
                    }
                    onClick={this.forgotPasswordButtonClick}
                    style={{fontSize: "0.95rem"}}
                  >
                    Reset
                  </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "ADMINGROUPNEEDED":
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.nextStep}
                      color="primary"
                      style={{fontSize: "0.95rem"}}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "USERINADMINGROUPNEEDED":
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.nextStep}
                      color="primary"
                      style={{fontSize: "0.95rem"}}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "VEEAMSTEPNEEDED":
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                  <Button
                    disabled={
                      this.state.inProgress || this.state.inProgressForgot
                    }
                    onClick={this.nextStep}
                    color="primary"
                    style={{fontSize: "0.95rem"}}
                  >
                    Continue
                  </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                  <Button
                    disabled={
                      this.state.inProgress || this.state.inProgressForgot
                    }
                    onClick={this.forgotPasswordButtonClick}
                    style={{fontSize: "0.95rem"}}
                  >
                    Forgot Password
                  </Button>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "DONE":
          card = (
            <React.Fragment>
              <div
                className="row"
                style={{
                  minWidth: "100%",
                  paddingBottom: "10px",
                }}
              >
                <div
                  className="btn-toolbar"
                  role="toolbar"
                  style={{
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div className="btn-group mr-2 mb-1" role="group">
                  <a
                                  href={  "/Login?email=" + this.state.email}
                    className="btn btn-primary"
                    style={{fontSize: "0.95rem"}}
                  >
                    Continue
                  </a>
                  </div>
                  <div className="btn-group mr-2 mb-1" role="group">
                    <Button
                      disabled={
                        this.state.inProgress || this.state.inProgressForgot
                      }
                      onClick={this.logout}
                      style={{fontSize: "0.95rem"}}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        default:
      }
    }
    return card;
  }

  getRightCard() {
    let currentStep = this.state.currentStep;
    let card = null;
    if (this.state.adminCheck && this.state.isAdmin && this.state.gotProgress) {
      let vboUserStep;
      let vboUserStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let applicationStep;
      let applicationStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let permissionStep;
      let permissionStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let adminGroupStep;
      let adminGroupStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let addToGroupStep;
      let addToGroupStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let veeamStep;
      let veeamStatus = <FontAwesomeIcon icon={faQuestionCircle} />;
      let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
      switch (currentStep) {
        case "VBOUSERNEEDED":
          if (this.state.inProgress) {
            vboUserStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            vboUserStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "APPLICATIONNEEDED":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          if (this.state.inProgress) {
            applicationStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            applicationStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "APPPERMISSIONSNEEDED":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          applicationStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          if (this.state.inProgress) {
            permissionStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            permissionStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "ADMINGROUPNEEDED":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          applicationStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          permissionStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          if (this.state.inProgress) {
            adminGroupStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            adminGroupStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "USERINADMINGROUPNEEDED":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          applicationStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          permissionStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          adminGroupStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          if (this.state.inProgress) {
            addToGroupStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            addToGroupStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "VEEAMSTEPNEEDED":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          applicationStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          permissionStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          adminGroupStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          addToGroupStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          if (this.state.inProgress) {
            veeamStatus = (
              <Spinner color="primary" style={{ maxWidth: "90%" }} />
            );
          } else if (this.state.warningText != "") {
            veeamStatus = (
              <p>
                <ReactTooltip
                  headingLine={failIcon}
                  tipText={this.state.warningText}
                  id="error"
                />
              </p>
            );
          }
          break;
        case "DONE":
          vboUserStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          applicationStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          permissionStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          adminGroupStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          addToGroupStatus = (
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
          );
          veeamStatus = <FontAwesomeIcon icon={faCheckCircle} color="green" />;
          break;
        default:
      }
      vboUserStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Creating VBO User on Azure</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{vboUserStatus}</td>
        </tr>
      );
      applicationStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Creating Application on Azure</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{applicationStatus}</td>
        </tr>
      );

      permissionStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Granting Application Permission</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{permissionStatus}</td>
        </tr>
      );

      adminGroupStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Creating Admin Group</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{adminGroupStatus}</td>
        </tr>
      );

      addToGroupStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Adding you to Admin Group</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{addToGroupStatus}</td>
        </tr>
      );

      veeamStep = (
        <tr style={{ padding: "0" }}>
          <td style={{ width: "85%", padding: "0" }}>
            <p style={{ fontSize: "large" }}>Adding Org to Veeam</p>
          </td>
          <td style={{ width: "15%", padding: "0" }}>{veeamStatus}</td>
        </tr>
      );
      card = (
        <React.Fragment>
          <br />
          <Table borderless responsive style={{ overflow: "hidden" }}>
            <tbody>
              {vboUserStep}
              {applicationStep}
              {permissionStep}
              {adminGroupStep}
              {addToGroupStep}
              {veeamStep}
            </tbody>
          </Table>
        </React.Fragment>
      );
    } else {
      card = <Image style={{ width: "90%" }} src={still} alt="CloudCover365" />;
    }
    return card;
  }

  hideSecretModal(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showSecretModal: false,
    });
  }

  render() {
    let leftCardContent = this.getLeftCard();
    let leftCardButtons = this.getLeftCardButtons();
    let rightCardContent = this.getRightCard();
    let secretModal = null;
    if (this.state.showSecretModal) {
      secretModal = (
        <Modal
          size="xl"
          isOpen={this.state.showSecretModal}
          toggle={this.hideSecretModal}
        >
          <ModalHeader toggle={this.hideSecretModal}>App Secret</ModalHeader>
          <ModalBody>
            <p>
              We encountered an error adding your organisation to Veeam. Please
              store this app secret somewhere secure as we may need it when you
              re-attempt set up.
            </p>
            <br />
            <p data-private>Secret: {this.state.appSecret}</p>
          </ModalBody>
        </Modal>
      );
    }
    let warningSection = [];
    if (this.state.passwordError) {
      warningSection.push(<b key="password">{this.state.passwordErrorText}</b>);
    } else if (this.state.microsoftError) {
      warningSection.push(
        <b key="microsoft">{this.state.microsoftErrorText}</b>
      );
    } else if (this.state.headerText != "") {
      warningSection.push(<b key="header">{this.state.headerText}</b>);
    } else if (this.state.inProgressForgot) {
      warningSection.push(
        <b key="forgot">Clearing VBO Account and Application from Azure.</b>
      );
    }
    return (
      <React.Fragment>
        {secretModal}
        {warningSection}
        <div className="card-deck">
          <div className="card shadow p-2 mb-3 bg-white rounded">
            <div className="card-body text-center" style={{padding: "10"}}>
              {leftCardContent}
            </div>
            <div className="card-footer text-center" style={{backgroundColor: "white", borderTop: "none"}}>
              {leftCardButtons}
            </div>
          </div>
          <div className="card shadow p-1 mb-3 bg-white rounded">
            <div className="card-body text-center" style={{ padding: "10" }}>
              {rightCardContent}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
