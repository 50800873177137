import React from "react";

const NotificationModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002",       
    };

    function closeModal(e) {
        e.stopPropagation();
        props.closeModal(true);
    }
    function submit(e) {
        props.closeModal(true);
        props.submitClick(props.action);
    }

    return (
        <div >
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" >
                            {props.message}
                        </div>
                        <div className="modal-footer" >
                            <input type="submit" name="ctl00$ContentPlaceHolder1$NotificationSubmit" value="Okay" id="ContentPlaceHolder1_NotificationSubmit" onClick={() => submit()} />
                            &nbsp;
                            <input type="submit" name="ctl00$ContentPlaceHolder1$NotificatiinCancel" value="Cancel" id="ContentPlaceHolder1_NotificationCancel" onClick={closeModal} />
                            </div>
                    </div>
                </div>
            </div>
            <div data-act-control-type="modalPopupBackground" id="NotificationModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>
    );
};

export default NotificationModal;
