import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';
const ColumnSelector = ({ onClose, isVisible }) => {
    const [isOpen, setIsOpen] = useState(false);

    const columnDefs = [
        { columnName: "resellerName", displayName: "Reseller Name" },
        { columnName: "orgName", displayName: "Organisation Name" },
        { columnName: "currentStatus", displayName: "Stage" },
        { columnName: "nextAction", displayName: "Next Action" },
        { columnName: "priceModel", displayName: "Price Model" },
        { columnName: "creationDate", displayName: "Date Created" },
        { columnName: "trialUntil", displayName: "Trial Date" },
        { columnName: "lastInvoice", displayName: "Last Inv" },
        { columnName: "goCardlessID", displayName: "GoCardless ID" },
        { columnName: "tennantDomain", displayName: "Tenant Domain" },
        { columnName: "xeroID", displayName: "Xero ID" },
        { columnName: "cwCompanyID", displayName: "CWCompanyID" },
        { columnName: "cwAgreementID", displayName: "CWAgreementID" },
        { columnName: "userCount", displayName: "Users" },
        { columnName: "spaceUsedGB", displayName: "GB" },
        { columnName: "orgId", displayName: "Org ID" },
        { columnName: "nextActionDate", displayName: "Next Action Date" },
        { columnName: "salesSource", displayName: "Sales Source" },
        { columnName: "adminEmail", displayName: "Admin Email" },
        { columnName: "notificationEmail", displayName: "Notification Email" },
        { columnName: "isReseller", displayName: "Reseller" },
        { columnName: "lastBackup", displayName: "Last Run" },
        { columnName: "warningCount", displayName: "Status" },
        { columnName: "disableAccess", displayName: "Disabled" },
        { columnName: "managed", displayName: "Managed" }
    ];
    let columnsToIncluded = ["resellerName",
        "orgName",
        "currentStatus",
        "nextAction",
        "creationDate",
        "trialUntil",
        "tennantDomain",
        "isReseller",
        "lastBackup",
        "warningCount",
        "disableAccess",
        "managed"
    ];
    const storedSelectedColumns = Cookies.get('selectedColumns');
    if (storedSelectedColumns) {
        columnsToIncluded = JSON.parse(storedSelectedColumns);
    }
    const [selectAll, setSelectAll] = useState(true)
    const [selectedColumns, setSelectedColumns] = useState(() => {
        return columnDefs.map(column => column.columnName).filter(columnName => columnsToIncluded.includes(columnName));
    });


    const handleColumnToggle = (columnField) => {

        localStorage.setItem("DefaultTab", "");

        setSelectedColumns(prevSelectedColumns => {
            const updatedColumns = prevSelectedColumns.includes(columnField) ?
                prevSelectedColumns.filter(field => field !== columnField) :
                [...prevSelectedColumns, columnField];

            // Call onClose with the updated selected columns
            return updatedColumns;
        });

    };
    useEffect(() => {
        if (isOpen) {

            onClose(selectedColumns);
            if (columnDefs.length !== selectedColumns.length) {
                setSelectAll(false)
            }
            else {
                setSelectAll(true)
            }
        }
    }, [selectedColumns]);
    /*useEffect(() => {
        if (isOpen) {
            console.log("useeffect of displayColumns", displayColumns)
            if (displayColumns.length !== 0) {
                setSelectedColumns(displayColumns)
            }
        }
    }, [displayColumns]);*/


    const handleDropdownToggle = (nextIsOpen, event, metadata) => {
        setIsOpen(nextIsOpen); // Update the state when the dropdown menu is opened or closed

        if (nextIsOpen) {
            const storedSelectedColumns = Cookies.get('selectedColumns');
            if (storedSelectedColumns) {
                columnsToIncluded = JSON.parse(storedSelectedColumns);
                setSelectedColumns(columnsToIncluded);
            }

        }

    };


    const handleSelectAll = () => {

        localStorage.setItem("DefaultTab", "");
        if (!selectAll) {
            setSelectedColumns(columnDefs.map(column => column.columnName));
            setSelectAll(true)
        }
        else {
            setSelectedColumns([])
            setSelectAll(false)
        }
    }
    return (
        <>
            {isVisible && 
                < Dropdown className="me-1" style={{ position: 'relative', top: '2.5rem', float: 'inline-end' }} autoClose="outside" onToggle={handleDropdownToggle}>
            <Dropdown.Toggle variant="primary" id="ContentPlaceHolder1_btnGrid">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" stroke="white" className="bi bi-columns mb-1 me-2" viewBox="0 0 16 16">
                    <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                </svg>
                Grid
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '61vh', overflowY: 'auto', padding: '0' }}>

                <Dropdown.Item onClick={() => handleSelectAll()} style={{ borderBottom: '1px solid #0000002d' }} id="grid-selectall">
                    <div>
                        <input
                            type="checkbox"
                            style={{ display: 'none' }}
                            id="col_def_selectall"
                            checked={selectAll}
                            onChange={() => handleSelectAll()}
                        />
                        {selectAll && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>}
                        <span style={{ fontSize: '13px' }} className={!selectAll ? 'ms-4' : undefined}>Select All</span>

                    </div>
                </Dropdown.Item >
                {columnDefs.map(column => (
                    <Dropdown.Item onClick={() => handleColumnToggle(column.columnName)} key={`grid-${column.columnName}`}>
                        <div>
                            <input
                                type="checkbox"
                                style={{ display: 'none' }}
                                id={column.columnName}
                                value={column.displayName}
                                checked={selectedColumns.includes(column.columnName)}
                                onChange={() => handleColumnToggle(column.columnName)}
                            />
                            {selectedColumns.includes(column.columnName) && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>}
                            <span style={{ fontSize: '13px' }} className={!selectedColumns.includes(column.columnName) ? 'ms-4' : undefined}>{column.displayName}</span>

                        </div></Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown >
            }
        </>
    );
};

export default ColumnSelector;
