import React, { useState, useMemo,useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif";

const DeleteOrgList = () => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const [removedOrgCount, setRemovedOrgCount] = useState(0);
    const [gridApi, setGridApi] = useState(null);
    const headerHeight = 30;

    const onGridReady = (params) => {
        setGridApi(params.api);
        loadRemovedOrgs(params);
        params.api.sizeColumnsToFit();

    }
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    async function loadRemovedOrgs(params) {
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        let data = await Axios.get(API_ROUTE + Routes.GET_REMOVEDORGLIST, config)
            .then((response) => {
                setRemovedOrgCount(Object.keys(JSON.parse(response.data)).length);
                params.api.setRowData(JSON.parse(response.data));
                return response;
            })
            .catch((reason) => {
                console.log(reason);
            });

    }
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);

        /*  if (gridApi?.getDisplayedRowCount() == 0)
              gridApi.showNoRowsOverlay();*/
    }
    const onDeleteOrgList = async(e,p) => {
        e.preventDefault();
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        const data = await Axios.post(`${API_ROUTE}${Routes.DELETE_REMOVED_ORG}/${p.data.orgId}`, {}, config)
            .then((response) => {
                loadRemovedOrgs(p);
                return response.data;

            })
            .catch((reason) => {
                console.log(reason);
            });
        if (data === "success")
            toast("Organization deleted successfully!", { autoClose: false });
        else
            toast("Failed to delete Organization.", { autoClose: false });


    }
    const coloumnDefs = [

        //{
        //    headerName: "ID", field: "orgId"
        //},
        {
            headerName: "Name", field: "orgName"
        },
        {
            field: 'name', headerName: 'Remove',
            cellRendererFramework: (params) => {                
                return (<div onClick={(e) => onDeleteOrgList(e, params)} ><i className="fal fa-times-circle"></i></div>)
            }
        }
    ]
    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: 500 }}>
            <div>Organization to be removed: {removedOrgCount == 0 ? "..." : removedOrgCount}</div>
            <input type="search" placeholder="search..." onChange={onFilterTextChange} className="form-control form-control-sm" />
            <AgGridReact
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                defaultColDef={defaultColDef}
                columnDefs={coloumnDefs}
                headerHeight={headerHeight}
                enableCellTextSelection="true"
                ensureDomOrder="true"
                overlayLoadingTemplate={
                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while loading Organizations to be deleted...</span></div></div>`}
                
                overlayNoRowsTemplate={
                    '<span className="ag-overlay-loading-center">No Organizations to be deleted</span>'
                }
            >
            </AgGridReact>
            <ToastContainer limit={4} style={{ fontSize: "small" }} />

        </div>

    );
};


export default DeleteOrgList;