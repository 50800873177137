import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
    auth: {
        authority: "https://login.microsoftonline.com/common", //Directory (tenant) ID Overview blade of App Registration
        clientId: "44c6d527-a263-40ae-a9eb-f0a05b93f083", //Application (client) ID
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

export const authenticationParameters = {
    scopes: [
        //'User.Read',
        'Domain.Read.All',
        'GroupMember.Read.All',
        //'Application.Read.All',
        'Application.ReadWrite.All',
        'User.ReadWrite.All'
    ]
}

export const authenticationParametersGraph = {
    scopes: [
        'openid'
    ]
}

export const options = {
    loginType: LoginType.Popup,
    tokenRefreshUri: window.location.origin,

}

export const loginAuthProvider = new MsalAuthProvider(config, authenticationParameters, options)