import React, { Component } from "react";
import { Route } from "react-router";
import {  Switch } from 'react-router-dom';
import Cookies from "universal-cookie";
import { Layout } from "./components/Layout";
import { Home } from "./components/Pages/Home";
import { SignUpLegacy } from "./components/Pages/SignUpLegacy";
//import './CSS/custom.css'
import { Login } from "./components/Pages/Login";
import { Maintenance } from "./components/Pages/Maintenance.js";
import { Logout } from "./components/Pages/Logout";
import { SignUp } from "./components/Pages/SignUp";
//import { DisablePage } from "./components/Pages/DisablePage";
import { SecurityPrivacyPolicy } from "./components/SecurityPrivacyPolicy";
//import { TreeTest } from './components/Pages/TreeTest';
/*//import 'bootstrap/dist/css/bootstrap.min.css';*/
import 'bootstrap/dist/css/bootstrap.css';
import LogRocket from "logrocket";
import { AppContextProvider } from "./components/MainComponents/AppContext";
import { AzureBackupAccount } from "./components/Pages/AzureBackupAccount";
import { BackupAccounts } from "./components/Pages/BackupAccounts";
import { BucketDashboard } from "./components/Pages/BucketDashboard";
import { DirectDebitSettings } from "./components/Pages/DirectDebitSettings";
import { EventLogs } from "./components/Pages/EventLogs";
import ExLogin from "./components/Pages/ExternalLogin";
import { Main } from "./components/Pages/Main";
//import { MasterAdmin } from "./components/Pages/MasterAdmin";
import { Restore } from "./components/Pages/Restore";
import { SignUpManual } from "./components/Pages/SignUpManual";
import { ThemeSelector } from "./components/ThemeSelector";
import { loginAuthProvider } from "./LoginAuthProvider";
import Pricing from "./components/Pages/Pricing";
import MasterAdmin from "./components/Pages/MasterAdmin";
import Reseller from "./components/Pages/Reseller";
import Settings from "./components/Pages/Settings";
import Watch365JobStatus from "./components/Pages/Watch365JobStatus";
import WeeklyChart from "./components/Pages/WeeklyChart";


import ContractCommit from "./components/Pages/ContractCommit";
import Invoice from "./components/Pages/Invoice";
import  { AuthProvider } from "./Store/Authentication-Context";
import PageNotFound from "./components/Pages/PageNotFound";
export default class App extends Component {
    constructor(props) {
        super(props);
        this.handleLogRocket();
        const cookies = new Cookies();
        let veeamToken = cookies.get("veeamToken");
        if (!veeamToken) {
            veeamToken = "";
        }
        this.state = {
            veeamToken: veeamToken,
        };
    }
    static displayName = App.name;

    async handleLogRocket() {
        await loginAuthProvider.getAccessToken();
        var accountInfo = await loginAuthProvider.getAccountInfo();
        if (accountInfo) {
            var email = accountInfo.account.userName;
            if (!(window.location.hostname.toLowerCase().includes("365demo") ||
                window.location.hostname.toLowerCase().includes("preview") ||
                window.location.hostname.toLowerCase().includes("365resellerdemo") ||
                (email !== "" && email.toLowerCase() === "admin@yorkshiremail.co.uk") ||
                window.location.hostname.toLowerCase().includes("localhost"))
            ) {
                console.log("Initialising logrocket.");
                LogRocket.init("eamvr0/cc365");
            }
        }
    }

    componentDidMount() { }

    render() {
        return (
            <AppContextProvider>
                <Layout>
                    <ThemeSelector>
                        <AuthProvider>
                            <Switch>
                        <Route exact path="/" component={Home} />
                        {/*<Route exact path="/" component={Pricing} />*/}
                        <Route exact path="/Login" component={Login} />
                        <Route exact path="/PricingModel" component={Pricing} />
                        <Route exact path="/ContractCommit" component={ContractCommit} />
                        <Route exact path="/ELogin" component={ExLogin} />
                        <Route exact path="/Logout" component={Logout} />
                        <Route exact path="/SignUp" component={SignUp} />
                        <Route exact path="/SignUpLegacy" component={SignUpLegacy} />
                        <Route exact path="/SignUpManual" component={SignUpManual} />
                        <Route exact path="/SecurityAndPrivacy" component={SecurityPrivacyPolicy} />
                        <Route exact path="/Restore" component={Restore} />
                        <Route exact path="/BackupAccounts" component={BackupAccounts} />
                        <Route exact path="/DirectDebitSettings" component={DirectDebitSettings} />
                        <Route exact path="/EventLogs" component={EventLogs} />
                        <Route exact path="/main" component={Main} />
                        <Route exact path="/Settings" component={Settings} />
                        <Route exact path="/Invoice" component={Invoice} />
                        <Route exact path="/MasterAdmin" component={MasterAdmin} />
                        <Route exact path="/Reseller" component={Reseller} />
                        <Route exact path="/Bucket" component={BucketDashboard} />
                        <Route exact path="/AzureBackupAccount" component={AzureBackupAccount} />
                        <Route exact path="/Check" component={Watch365JobStatus} />
                        <Route exact path="/JobManagement" component={WeeklyChart} />
                        <Route exact path="/Maintenance" component={Maintenance} />
                            {/*<Route path="*" component={PageNotFound} />*/}
                                <Route component={PageNotFound} />
                            </Switch>
                        </AuthProvider>
                    </ThemeSelector>
                </Layout>
            </AppContextProvider>
        );
    }
}
