import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  Button
} from "reactstrap";
import { authProvider } from "../../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import LogRocket from 'logrocket';
import { BackupAccountProgress } from "../BackupAccountProgress";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import decryptParameter from "../../Helpers/DeCryptParameter";
export class BackupAccounts extends Component {
  constructor(props) {
      super(props);
      const urlParams = new URLSearchParams(window.location.search);
      const orgId = decryptParameter(urlParams.get('orgId'));
        this.state = {
            theme: null,
            orgId: orgId,
            orgName: (urlParams.get('orgName'))
        };
  }
  static displayName = BackupAccounts.name;

  render() {
    const WidgetTheme = React.lazy(() => import('../ThemeObjects/WidgetTheme'));
    let chosenTheme = <WidgetTheme/>
    let returnObject = (
    <div style={{backgroundImage: "none", backgroundColor: "white"}}>
      <React.Suspense fallback={<></>}>
        {chosenTheme}
      </React.Suspense>     
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              LogRocket.identify(accountInfo.account.userName, {
                name: accountInfo.account.name
              });
              return (
                <React.Fragment>
                      <BackupAccountProgress page={true} orgId={this.state.orgId} orgName={this.state.orgName} />
                </React.Fragment>
              );
            case AuthenticationState.Unauthenticated:
              if (error) {
                if(error.errorCode){
                  if(error.errorCode == "user_cancelled"){
                    return (
                      <React.Fragment>
                        <p>
                          Authentication Error. Please log out and try again.
                        </p>
                        <Button onClick={logout}>Log Out</Button>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment>
                        <p>
                          Authentication Error. Please log out and try again.
                        </p>
                        <Button onClick={logout}>Log Out</Button>
                      </React.Fragment>
                    ); 
                  }
                } else {
                  return (
                    <React.Fragment>
                        <p>
                          Authentication Error. Please log out and try again.
                        </p>
                        <Button onClick={logout}>Log Out</Button>
                      </React.Fragment>
                  ); 
                }                           
              } else {
                return (
                  <React.Fragment>
                    <p>
                      Please Log In
                    </p>
                    <Button onClick={login}>Log In</Button>
                  </React.Fragment>
                );
              }
            case AuthenticationState.InProgress:
              return (
                <React.Fragment>
                  <p>
                    Authenticating with Microsoft.
                  </p>
                </React.Fragment>
              );
            default:
              return <p>Error.</p>;
          }
        }}
      </AzureAD>
    </div>      
    );
      const currentYear = new Date().getFullYear(); 

    return(<div id="ContentPlaceHolder1_UpdatePanel1">
    <div className="row login">
      <div className="col"></div>
      <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 text-center">
        <div className="card loginbox">
          <div className="card-body">
            {returnObject}
          </div>
          <div className="card-footer">
            <section className="small">
              <SubscriberAgreement />
              |
                            <SecurityAndPrivacy />
                            <br />© Saas It {currentYear}
            </section>
          </div>
          <span
            id="ContentPlaceHolder1_lblLoginResponse"
            style={{ color: "red" }}
          ></span>
        </div>
      </div>
      <div className="col"></div>
    </div>
  </div>);
  }
}