import React,{ Component } from 'react';
import { loginAuthProvider } from '../LoginAuthProvider';



const AuthenticationContext = React.createContext();
export const AuthConsumer = AuthenticationContext.Consumer;
export class AuthProvider extends Component {
    state = {
        Authtoken: "",
        time: new Date(),
        RepoData: {}
    }
    setAuthContext = async ( newtime) => {
        const timeDifference = newtime - this.state.time;
        if (Math.floor(timeDifference / (1000 * 60)) > 20 || this.state.Authtoken === "") {
            var token = await loginAuthProvider.getAccessToken();
            var tokenString = token.accessToken;
            this.setState({ Authtoken: tokenString, time: newtime });
        }
        return this.state.Authtoken;
    }
    setRepoData = async ({ id, data }) => {
        this.setState(prevState => {
            if (Object.keys(prevState.RepoData).length === 0) {
                return { ...prevState, RepoData: { [id]: data } };
            } else {
                if (!(id in prevState.RepoData)) {
                    return { ...prevState, RepoData: { ...prevState.RepoData, [id]: data } };
                }
                return prevState;
            }
        });
    }
    checkDataForId = (id) => {
        if (this.state.RepoData) {
            return id in this.state.RepoData;
        }
        else {
            return false
        }
    }
    render() {
        const { Authtoken, time, RepoData } = this.state;
        return (<AuthenticationContext.Provider value={{ Authtoken, time: time, setAuthContext: this.setAuthContext, checkDataForId: this.checkDataForId, setRepoData: this.setRepoData, RepoData }}>
            {this.props.children}
        </AuthenticationContext.Provider>)
    }
}

export default AuthenticationContext;