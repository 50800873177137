import React from "react";

const HelpModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"

    };

    function closeModal(e) {
        e.stopPropagation();
        props.closeModal(true);
    }
    return (
        <div >
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"

            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                CloudCover 365 - Help
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" >  <iframe
                            src={"https://cloudcover365.virtualdcs.co.uk/helpme/" + props.about}
                            title="W3Schools Free Online Web Tutorials"
                            style={{ height: "550px", minWidth: "100%", width: "inherit" }}
                        ></iframe></div>
                        <div className="modal-footer" />
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />


        </div>
    );
};

export default HelpModal;
