import React, { Component, useState } from 'react';
import { Tooltip } from 'reactstrap';

export class ReactTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tooltipOpen: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(){
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <div>
                <p><span style={{color:"blue"}} href="#" id={this.props.id}>{this.props.headingLine}</span></p>
                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle}>
                    {this.props.tipText}
                </Tooltip>
            </div>
        );
    }
}