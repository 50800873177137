import React, { useState, useMemo,useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif";

const StrandedBucket = () => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const [bucketCount, setBucketCount] = useState(0);
    const [gridApi, setGridApi] = useState(null);
    const headerHeight = 30;

    const onGridReady = (params) => {
        setGridApi(params.api);
        loadStrandedBucket(params);
        params.api.sizeColumnsToFit();

    }
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    async function loadStrandedBucket(params) {
        var tokenString = await setAuthContext("", new Date());
        //this.setState({ token: tokenString, });
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        let data = await Axios.get(API_ROUTE + Routes.GET_BUCKETS, config)
            .then((response) => {
                var data = response.data.map(name => { return { name } });
                setBucketCount(response.data.length);
                let selectItems = data;
                params.api.setRowData(data);
                return selectItems;
            })
            .catch((reason) => {
                console.log(reason);
            });

    }
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);

        /*  if (gridApi?.getDisplayedRowCount() == 0)
              gridApi.showNoRowsOverlay();*/
    }
    const coloumnDefs = [

        {
            headerName: "Bucket Name", field: "name"
        }
    ]
    return (
        //let bucketLabel = ([]);



        <div className="ag-theme-alpine" style={{ height: 400, width: 500 }}>
            <div>Stranded bucket count: {bucketCount == 0 ? "..." : bucketCount}</div>
            <input type="search" placeholder="search..." onChange={onFilterTextChange} className="form-control form-control-sm" />

            <AgGridReact
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                defaultColDef={defaultColDef}
                columnDefs={coloumnDefs}
                headerHeight={headerHeight}
                enableCellTextSelection="true"
                ensureDomOrder="true"
                overlayLoadingTemplate={
                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                overlayNoRowsTemplate={
                    '<span className="ag-overlay-loading-center">No records to show</span>'
                }
            >

            </AgGridReact>
        </div>

    );
};


export default StrandedBucket;