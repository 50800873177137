import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import "../../CSS/general.css";
import { loginAuthProvider } from "../../LoginAuthProvider";
import LogRocket from "logrocket";
import { LogViewer } from "../LogViewer";
import StrandedBucket from "../RemovalProcess/StrandedBucket";
import DeleteOrgList from "../RemovalProcess/DeleteOrgList";

import AuthenticationContext from "../../Store/Authentication-Context";

export class BucketDashboard extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            orgId: "",
            isValid: false,
        };
        this.validateToken = this.validateToken.bind(this);

    }
    static displayName = BucketDashboard.name;
    componentDidMount() {
        this.validateToken();
    }
    async validateToken(event) {
        if (event) {
            event.preventDefault();
        }
        const { setAuthContext } = this.context;

        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        let data = await Axios.get(API_ROUTE + Routes.VALIDATE_TOKEN_ADMIN, config)
            .then((response) => {

                let isValid = JSON.parse(response.data);
                if (isValid) {
                    this.setState({
                        isValid: true
                    });
                } else {
                    window.location.href = "/login";
                }
            })
            .catch((reason) => {
                //console.log("catch" + reason);
            });

    }

    render() {
        let BucketBlock = (
            <div>

            </div>
        );
        let OrgBlock = (
            <div>

            </div>
        );
        if (this.state.isValid) {
            BucketBlock = (<StrandedBucket />);
            OrgBlock = (<DeleteOrgList/>);
        }

        let displaySection = (
            <AzureAD provider={loginAuthProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            LogRocket.identify(accountInfo.account.userName, {
                                name: accountInfo.account.name,
                            });
                            return (
                                <div className="m-1">
                          
                                    <div className={'row col-lg-12'}>
                                        <div className={'row col-lg-5'}>{BucketBlock}</div>
                                        <div className={'row col-lg-5'}>{OrgBlock}</div>
                                    </div>
                                </div>);
                        case AuthenticationState.Unauthenticated:
                            window.location.href = "/login";
                            return (
                                <React.Fragment>
                                    <div>Authentication Failed</div>
                                </React.Fragment>
                            );
                        default:
                            return <p>Please contact support.</p>;
                    }
                }}
            </AzureAD>
        );
        return (
            <React.Fragment>
                {displaySection}
            </React.Fragment>

        );
    }
}
