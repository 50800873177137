import React, { Component } from 'react';
import transpix from '../images/transpix.png';
import Banner65 from '../images/Banner65new1.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <div className=" titlebar custStyle" style={{ display: "flex", justifyContent: 'space-between' }}>
                <div className="col-3" style={{ textAlign: "left", display: 'flex', alignItems: 'center' }}>
                    <div className="menulogo align-top  m-0 ms-2 mb-1 mt-1" alt="" ></div>
                </div>

                <div className="titletext ">

                </div>
                <div className="" style={{ paddingRight: 0 }}>
                    <img className="img-fluid mr-1" style={{ marginBottom: "2px", height: "100%" }} src={Banner65} alt="" />
                </div>
            </div>


        );
    }
}
