import React, { useContext, useState, useEffect } from "react";
import { Input } from "reactstrap";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";
import { AgGridReact } from "ag-grid-react";
import CompanyDropDown from "./CompanyDropDown";
import { API_ROUTE, LEGACY_SITE, Routes, Urls } from "../../Helpers/Constants";
import AuthenticationContext from "../../Store/Authentication-Context";
import Axios from "axios";
import LoaderGif from "../../images/365 loading.gif";
const Step1 = (props) => {
    const [appContext] = useContext(AppContext);
    const [error, setError] = useState("");
    const { setAuthContext } = useContext(AuthenticationContext);
    const [helpPopup, setHelpPopup] = useState(true);

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [uniqueAdditionLines, setUniqueAdditionLines] = useState([]);
    const [additionLines, setAdditionLines] = useState([]);

    const handleCellValueChanged = async (params) => {
     ;
        if (params.newValue != 0) {

            // Update uniqueAdditionLines
            const updatedUniqueAdditionLines = uniqueAdditionLines.map(row => {
                if (row.orgId === params.data.orgId) {
                    return { ...row, cwCompanyID: params.newValue };
                }
                return row;
            });
            setUniqueAdditionLines(updatedUniqueAdditionLines);


            // Prepare data for the API call
            const data = {
                OrgId: params.data.orgId,
                Setting: "CWCompanyID",
                SettingValue: params.newValue
            };

            // Make the API call to update the setting on the server
            try {
                const tokenString = await setAuthContext("", new Date());
            
                const config = {
                    headers: {
                        Authorisation: `Bearer ${tokenString}`,
                    },
                };

                await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                    .then((response) => {

                    })
                    .catch((reason) => {
                        console.error("Error updating companyID:", reason);
                    });
            } catch (error) {
                console.error("Error in handleCellValueChanged:", error);
            }

      
        }
    };

    const columnDefs = [
        { headerName: "Org ID", field: "orgId",flex:1 },
        { headerName: "Org Name", field: "orgName", flex: 1 },
        {
            headerName: "CW Company ID", field: "cwCompanyID", editable: true, cellEditorFramework: CompanyDropDown,  cellEditorParams: {
                values: props.companies
            }, onCellValueChanged: handleCellValueChanged, flex: 1
        },
        { headerName: "CW Agreement ID", field: "cwAgreementID", flex: 1 },
    ];
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const validate = () => {

        CreateCompanies().then((result) => {

            props.nextStep();

        }).catch((error) => {
            console.error("Error in creating contacts or agreements:", error);
        });
    }

    const onInputChanged = (event) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setError("");
        
    };
    async function CreateCompanies() {
        const createCompanies = [];
        let iscompanyCreated = false;
        console.log(uniqueAdditionLines);
        // Iterate over companyContacts to prepare the contacts that need to be created
        uniqueAdditionLines.forEach((addition) => {
            if (addition.cwCompanyID === 0) {
                iscompanyCreated = true;
                const newCompany  = {
                    id: 0,
                    Identifier: addition.orgName,
                    
                    name: addition.orgName
                    }

                    

                createCompanies.push(newCompany);
            }
        });


        // Assuming you have an endpoint to create contacts in ConnectWise
        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
            },
        };

        try {

            await Axios.post(API_ROUTE + Routes.CREATE_CONNECTWISE_COMPANY, createCompanies, config)
                .then((response) => {
                   
                    if (response && response.data) {
                        const updatedAdditionLines = [...uniqueAdditionLines];

                        response.data.forEach((company) => {
                            // Update all additionLines that match the company name
                            updatedAdditionLines.forEach(line => {
                                if (line.orgName === company.name) {
                                    line.cwCompanyID = company.id;
                                    const data = {
                                        OrgId: line.orgId,
                                        Setting: "CWCompanyID",
                                        SettingValue: company.id.toString()
                                    };

                                    try {
                                       // const tokenString =  setAuthContext("", new Date());

                                       

                                         Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                                            .then((response) => {

                                            })
                                            .catch((reason) => {
                                                console.error("Error updating companyID:", reason);
                                            });
                                    } catch (error) {
                                        console.error("Error in handleCellValueChanged:", error);
                                    }
                                }
                            });
                        });

                            // Update the additionLines state with the updated array
                        props.updateUniqueAdditionLines(updatedAdditionLines);
                        }
                   
                
                })
                .catch((error) => {
                    console.error("Error creating contact:", error);
                });

        } catch (error) {
            console.error("Error in CreateContacts:", error);
        }
        if (iscompanyCreated == false) {
            props.updateUniqueAdditionLines(uniqueAdditionLines);
        }

        
    }
   

    function closeModal(params) {
        setHelpPopup(params);
    }
    const getUniqueAdditionLines = (additionLines) => {
        const unique = [];
        const map = new Map();
        for (const item of additionLines) {
            if (!map.has(item.orgId)) {
                map.set(item.orgId, true); // set any value to Map
                unique.push({
                    orgId: item.orgId,
                    orgName: item.orgName,
                    cwCompanyID: item.cwCompanyID,
                    cwAgreementID: item.cwAgreementID,
                    adminEmail: item.adminEmail,
                    notificationEmail: item.notificationEmail
                });
            }
        }
        return unique;
    };

    useEffect(() => {

        setAdditionLines(props.additionLines);
        if (props.additionLines) {
            const uniqueData = getUniqueAdditionLines(props.additionLines);
            setUniqueAdditionLines(uniqueData);
            
        }
    }, [props.additionLines]);

    return (
        <div id="step1">
            <div>
                <div id="UpdatePanel1">
                    <div className="card">
                        <h4 className="card-header ">
                            <span id="TitleLabel" className="SectionTitle">Company Check</span>
                            
                        </h4>
                        <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                            <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={uniqueAdditionLines}
                                onGridReady={onGridReady}
                                    domLayout="normal"
                                    overlayLoadingTemplate={
                                        `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                            />
                            </div>
                        </div>
                    </div>
                    <div>
                        <table cellSpacing="5" cellPadding="5" align="right">
                            <tbody>
                                <tr>
                                    <td >
                                        <ActionButtons  {...props} nextStep={validate} currentStep={1} totalSteps={4} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div data-act-control-type="modalPopupBackground" id="PopMsg_backgroundElement" className="Background" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div><div data-act-control-type="modalPopupBackground" id="MPE_backgroundElement" className="AnotherModalPopup" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div></div>
                <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step1-NamingaJob.html" />
            </div>
        </div>
    );
}
export default Step1;