
import React, { useState, useMemo, useEffect, useRef, useLocation } from "react";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";

function Watch365JobStatus(props) {

    const [statusHistory, setStatusHistory] = useState(null);

    const triggerTime = new Date().toISOString();


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const runningValue = searchParams.get('Running');
        const failValue = searchParams.get('Fail');



        const handlePlanConfirm = async () => {

            const config = {
                headers: {
                    Authorisation: `Bearer NOT REQUIRED`,
                },
            };

            try {
                const response = await Axios.get(API_ROUTE + Routes.GET_365_JOB_STATUS + "/" + runningValue + "/" + failValue, config);
                
                // Convert JSON to XML
                if (response && response.data && response.data.result) {


                    const xmlData =
                        //"<?xml version=\"1.0\" encoding=\"UTF-8\"?>"
                        //+
                        "<pingdom_http_custom_check>"

                        + "<status>" + response.data.result.jobStatus + "</status>"

                        + "<response_time>" + response.data.result.responseTime + "</response_time>"

                        + "<running>" + response.data.result.running + "</running>"

                        + "<failed>" + response.data.result.failed + "</failed>"

                        + "</pingdom_http_custom_check>"

                    setStatusHistory(xmlData);

                }
                else {
                    const xmlData =
                        //"<?xml version=\"1.0\" encoding=\"UTF-8\"?>"
                        //+
                        "<pingdom_http_custom_check>"

                        + "<status>Fail</status>"

                        + "<response_time>0</response_time>"

                        + "<running>0</running>"

                        + "<failed>0</failed>"

                        + "</pingdom_http_custom_check>"

                    setStatusHistory(xmlData);


                }


                // Update state or perform other actions based on response
            } catch (error) {
                console.error("Error fetching job status:", error);
            }
        };

        if (runningValue) {
            handlePlanConfirm();
        }
    }, []);

    return (
        <>
            {statusHistory}
        </>

    );

}

export default Watch365JobStatus;