import React, { useContext, useState, useEffect } from "react";
import { Input } from "reactstrap";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import ActionButtons from "./ActionButton";

const Step1 = (props) => {
    const [appContext] = useContext(AppContext);
    const [error, setError] = useState("");
    const [jobName, setJobName] = useState("");
    const [jobDesc, setJobDesc] = useState("");
    const [helpPopup, setHelpPopup] = useState(true);

    const onInputChanged = (event) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        setError("");
        if (targetName == "name")
            setJobName(targetValue);
        else
            setJobDesc(targetValue);
    };

    const validate = () => {
        if (!jobName) setError("Required");
        else {
            setError("");
            appContext.job.name = jobName;
            appContext.job.description = jobDesc;
            props.nextStep();
        }
    };

    function closeModal(params) {
        setHelpPopup(params);
    }

    useEffect(() => {
            setJobName(appContext.job.name);
            setJobDesc(appContext.job.description);
    }, [appContext.job]);

    return (
        <div id="step1">
            <div>
                <div id="UpdatePanel1">
                    <div className="card">
                        <h4 className="card-header ">
                            <span id="TitleLabel" className="SectionTitle">Job Detail </span>
                            <a id="Job_btnHelp" style={{ float:'right' }} onClick={() => closeModal(false)}><i className="fad fa-question"></i></a>
                        </h4>
                        <div className="card-body ">
                            <table cellSpacing="0" cellPadding="0" id="Wizard1" style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                                <tbody>
                                    <tr style={{ height: "100%" }}>
                                        <td>
                                            {/*<span className="wizardtitles"> Specify the job name and description </span>*/}
                                            <div className="form-group">
                                                <span id="Wizard1_lblJobName" className="wizardtext" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500', fontSize:'medium' }}>Name</span>
                                                <Input
                                                    type="text"
                                                    id="WizardJobname"
                                                    name="name"
                                                    placeholder="Enter job name"
                                                    onChange={onInputChanged}
                                                    value={jobName}
                                                />
                                                <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span>
                                            </div>
                                            <div className="form-group mt-2">
                                                <span id="Wizard1_lblDescription" className="wizardtext" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500', fontSize: 'medium' }}>Description</span>
                                                <br />
                                                <Input type="textarea" rows="4" name="description" id="exampleText" value={jobDesc} onChange={onInputChanged} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer p-0">
                            <table cellSpacing="5" cellPadding="5" align="right">
                                <tbody>
                                    <tr>
                                        <td >
                                            <ActionButtons {...props} nextStep={validate} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div data-act-control-type="modalPopupBackground" id="PopMsg_backgroundElement" className="Background" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div><div data-act-control-type="modalPopupBackground" id="MPE_backgroundElement" className="AnotherModalPopup" style={{ display: "none", position: "fixed", left: "0px", top: "0px", zIndex: "10000" }} ></div></div>
                <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step1-NamingaJob.html" />
            </div>
        </div>
    );
}
export default Step1;