import React, { useState, useContext, useEffect, useMemo } from "react";
import "../../CSS/general.css";
import Axios from "axios";
import ActionButtons from "./ActionButton";
import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";
import HelpModal from "../../components/MainComponents/HelpMessagePopup";
import RepoModalPopup from "../../components/MainComponents/RepoModalPopup";
import { AppContext } from "../MainComponents/AppContext";
import AlertModal from "../MainComponents/AlertMessagePopup";
import { loginAuthProvider } from "../../LoginAuthProvider";
//import Spinner from "reactstrap/lib/Spinner";
import AuthenticationContext from "../../Store/Authentication-Context";
import { AgGridReact } from 'ag-grid-react';
import LoaderGif from "../../images/365 loading.gif";
const Step4 = (props) => {
    const { setAuthContext, setRepoData, checkDataForId, RepoData } = useContext(AuthenticationContext);
    const [error, setError] = useState("");
    const [helpPopup, setHelpPopup] = useState(true);
    const [inProgress, setInProgressp] = useState(true);
    
    const [appContext] = useContext(AppContext);
    const [additionLines, setAdditionLines] = useState([]);
    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });
    const [AlertPop, setAlertPop] = useState(true);
   
    const validate = () => {
        props.closePopupModal();
    };
    const [gridApi, setGridApi] = useState([]);
   

    function closeModal(params) {
        setHelpPopup(params);
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
    }
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
       // flex: 1
    }), []);
    
    const columnDefs = [

        { field: 'orgId', headerName: 'Organisation ID', width: 220, },
        { field: 'orgName', headerName: 'Organisation Name', width: 250, },
        { field: 'cwCompanyID', headerName: 'Company ID', width: 100, },
        { field: 'cwAgreementID', headerName: 'Agreement ID', width: 100, },
        { field: 'cwProductID', headerName: 'Product ID', width: 100, },
        { field: 'invoiceDescription', headerName: 'Invoice Description', width: 350, },
        { field: 'qty', headerName: 'Qty', width: 75, },
        { field: 'price', headerName: 'Price', width: 100, },
        { field: 'status', headerName: 'Status', width: 100, },

    ];
    

    const popupSwap = (show, reload = false) => {
        ////await getPriceModel()
       // setShowModal(show);
    }

    const clearData = () => { }

    const cancel = () => {
        //setShowModal(false);
    }

    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }

    async function processAdditionLines() {
        
        
        const tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ${tokenString}`,
            },
        };

        try {

            await Axios.post(API_ROUTE + Routes.PROCESS_CONNECTWISE_ADDITIONLINES, props.additionLines, config)
                .then((response) => {
           
                    if (response && response.data) {
                        

                        // Update the additionLines state with the updated array
                        setAdditionLines(response.data);
                        setInProgressp(false);
                    }


                })
                .catch((error) => {
                    console.error("Error creating contact:", error);
                });

        } catch (error) {
            console.error("Error in CreateContacts:", error);
        }


    }

    useEffect(() => {
        processAdditionLines();
        
    }, [props.additionLines]);


    

    return (

        <div id="step4">
            <div id="UpdatePanel1">
                <div className="card">
                    <h4 className="card-header ">
                        <span id="TitleLabel" className="SectionTitle">Update Additions &nbsp;</span>
                      
                    </h4>
                    <div className="card-body" style={{ borderStyle: "None", height: "400px", width: "100%" }}>
                        <div className="ag-theme-alpine ms-2" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "99%", height: '100%', overflowY: "auto" }}>
                        
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={additionLines}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                domLayout="normal"
                                overlayLoadingTemplate={
                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            inProgress &&
                            <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                                <img src={LoaderGif} alt="loading" style={{ height: '45px' }} />
                            </div>
                        }
                        {
                            !inProgress &&
                            <table cellSpacing="5" cellPadding="5" align="right">
                                <tbody>
                                    <tr>
                                        <td >
                                                <ActionButtons {...props} lastStep={validate}  />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                <input type="hidden" name="btnhelp" id="btnhelp" />
            </div>
            <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="Step4-Scheduling.html" />
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
           
        </div>
    );
}
export default Step4;