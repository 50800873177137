import React, { useState, useEffect, useRef, useContext } from "react";
import { Card, Button, Container, ListGroup, Form, FormGroup, Col, Row, InputGroup } from 'react-bootstrap';
import { NavBar } from "../NavBar";

import "../../CSS/general.css";
import { AppContext } from "../MainComponents/AppContext";
import Axios from "axios";
import { loginAuthProvider } from "../../LoginAuthProvider";

import { API_ROUTE, Routes, Urls } from "../../Helpers/Constants";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AuthenticationContext from "../../Store/Authentication-Context";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trim } from "lodash";
import LoaderGif from "../../images/365 loading.gif";

const Settings = () => {
    const { setAuthContext } = useContext(AuthenticationContext);
    const authContext = useContext(AuthenticationContext);
    const [org, setOrg, navBarSetting, setNavBarSetting] = useContext(AppContext);
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const [managed, setManaged] = useState();
    const [endUserRecovery, setEndUserRecovery] = useState();
    const [adminEmailAddress, setAdminEmailAddress] = useState();
    const [notificationEmailAddress, setNotificationEmailAddress] = useState();
    const [notificationType, setNotificationType] = useState();
    const [goCardlessID, setGoCardlessID] = useState();
    const [resellerID, setResellerID] = useState();
    const [tennantName, setTennantNameD] = useState();
    const [restoreEmailType, setRestoreEmailType] = useState();
    const [encryption, setEncryption] = useState();
    const [urlParams, setParams] = useState(new URLSearchParams(window.location.search));
    const [isLoading, setIsLoading] = useState(true); 
    const [groups, setGroups] = useState([]);
    const [checkboxes, setCheckboxes] = useState({ VeeamAdmin: false, Setting: false, MainPage: false, RestorePage: false, EndUserRestore: false });
    const [disabledCheckboxes, setDisabledCheckboxes] = useState({ VeeamAdmin: false, Setting: false, MainPage: false, RestorePage: false, EndUserRestore: false });

    const [validation, setValidation] = useState({
        isAdminEmailValid: true,
        adminEmailErrorMessage: "",
        isNotificationEmailValid: true,
        notificationEmailErrorMessage: "",
        isEncryptionValid: true,
        encryptionErrorMessage: "",
    });

    useEffect(() => {

        getAllSettings();
        async function getAllSettings() {

            try {

                var tokenString = await setAuthContext("", new Date());

                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                    },
                };

                const inputData = {
                    OrgId: urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId"),
                    ServerPath: localStorage.getItem("ServerPath") ? localStorage.getItem("ServerPath") : "",
                    Url: "/v7/Organizations/" + (urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId")),
                    TenantName: localStorage.getItem("TenantName") ? localStorage.getItem("TenantName") : "",
                    EmailAddress: localStorage.getItem("UserName") ? localStorage.getItem("UserName") : "",
                };
                await Axios.post(API_ROUTE + Routes.GET_ALL_SETTINGS, inputData, config)
                    .then((response) => {

                        if (response && response.data) {

                            setManaged(response.data.managed);
                            setEndUserRecovery(response.data.endUserRecovery);
                            setAdminEmailAddress(response.data.adminEmailAddress);
                            setNotificationEmailAddress(response.data.notificationEmailAddress);
                            setNotificationType(trim(response.data.notificationType));
                            setGoCardlessID(response.data.goCardlessID);
                            setResellerID(response.data.resellerID);
                            setTennantNameD(response.data.tennantName);
                            setRestoreEmailType(response.data.restoreMailTo);
                            setEncryption(response.data.encryption);
                            //  setEncryption(false);
                            /*if ((goCardlessID || goCardlessID === "")) {
                                setdirectDebitStatus("Currently you have no Direct Debit, ");
                            }*/

                        }

                    })
                    .catch((reason) => {


                    });

            }
            catch (error) { }
            setIsLoading(false);
        }


    }, []);
    const showToast = (type, message) => {
        if (type === "success") {
            toast.success(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
        else if (type === "error") {
            toast.error(message, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
    };
    useEffect(() => {
        fetchGroups();
    }, []);
    async function fetchGroups() {
        try {

            var tokenString = await setAuthContext("", new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await Axios.get(API_ROUTE + Routes.GET_AllAZURE_GROUP, config);
            setGroups(response.data);  
            updateCheckboxes(response.data);
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
    }


    function updateCheckboxes(groups) {
        const updatedCheckboxes = {
            VeeamAdmin: groups.some((group) => group.displayName === 'Veeam365Admin'),
            Setting: groups.some((group) => group.displayName === 'Veeam365Engineer'),
            MainPage: groups.some((group) => group.displayName === 'Veeam365JobManagement'),
            RestorePage: groups.some((group) => group.displayName === 'Veeam365Restore'),
            EndUserRestore: groups.some((group) => group.displayName === 'Veeam365EndUserRecovery'),
        };

        const updatedDisabledCheckboxes = {
            VeeamAdmin: updatedCheckboxes.VeeamAdmin,
            Setting: updatedCheckboxes.Setting,
            MainPage: updatedCheckboxes.MainPage,
            RestorePage: updatedCheckboxes.RestorePage,
            EndUserRestore: updatedCheckboxes.EndUserRestore,
        };

        setCheckboxes(updatedCheckboxes);
        setDisabledCheckboxes(updatedDisabledCheckboxes);
    }


    function handleCheckboxChange(event) {
        const { name, checked } = event.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));
        modernCreateAndAddAdminGroup(name, checked);
    }

    async function modernCreateAndAddAdminGroup(groupName, isChecked) {
        const groupMap = {
            VeeamAdmin: 'Veeam365Admin',
            Setting: 'Veeam365Engineer',
            MainPage: 'Veeam365JobManagement',
            RestorePage: 'Veeam365Restore',
            EndUserRestore: 'Veeam365EndUserRecovery',
        };
        const displayName = groupMap[groupName];

        try {
            var tokenString = await setAuthContext("", new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ${tokenString}`,
                    'Content-Type': 'application/json',
                },
            };

            if (isChecked) {
                await Axios.post(API_ROUTE + Routes.CREATE_GROUP, JSON.stringify([displayName]), config)
                    .then((response) => {
                        fetchGroups();
                        toast.success(displayName +" User group created successfully", {
                            position: 'top-right',
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                        });
                    });
            }
        } catch (error) {
            console.error('Error creating groups:', error);
        }
    }

    async function setSettings(settingsName,settingsValue) {

        try {
            var tokenString = await setAuthContext("", new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                },
            };
            const inputData = {
                OrgId: urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId"),
                Setting: settingsName,
                SettingValue: settingsValue,

            };
            await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, inputData, config)
                .then((response) => {

                    if (response && response.data && response.data.length > 0) {


                    }

                })
                .catch((reason) => {


                });

        }
        catch (error) { }

    }

    async function setEncryptionSettings(settingsValue) {

        try {

            var tokenString = await setAuthContext("", new Date());

            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                },
            };
            const inputData = {
                OrgId: urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId"),
                ServerPath: localStorage.getItem("ServerPath") ? localStorage.getItem("ServerPath") : "",
                PostString: settingsValue,
                TenantName: localStorage.getItem("TenantName") ? localStorage.getItem("TenantName") : "",
                EmailAddress: localStorage.getItem("UserName") ? localStorage.getItem("UserName") : "",
                Url: "/v7/Organizations/" + (urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId")),


            };
            await Axios.post(API_ROUTE + Routes.SET_ENCRYPTION, inputData, config)
                .then((response) => {

                    if (response && response.data && response.data) {

                        toast(response.data);

                        if (response.data == "Your key has been added to the store") { setEncryption("true") }
                    }

                })
                .catch((reason) => {


                });

        }
        catch (error) { }

    }

    const handleChkBoxDelegatedMgt = (event) => {
        setManaged(event.target.checked.toString()); // Update the state with the new checkbox value
        setSettings('Managed', event.target.checked.toString());
        if (event.target.checked) {
            toast.success("Enabled Delegated management for organisation", {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
        else {
            toast.success(" Disabled Delegated management for organisation", {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }

    };
    const handleendUserRecovery = (event) => {
        console.log("check localstorage setting", localStorage.getItem("IsVeeam365Engineer"))
        setEndUserRecovery(event.target.checked.toString()); // Update the state with the new checkbox value
        setSettings('EndUserRecovery', event.target.checked.toString());
        if (event.target.checked) {
            toast.success("Enabled End User Recovery for organisation", {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
        else {
            toast.success("Disabled End User Recovery for organisation", {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
    };

    const setUpClick = (event) => {
        window.parent.location.href = '/SignUp';
    };

    const workerClick = (event) => {
        window.parent.location.href = '/BackupAccounts?orgId=' + (urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId")) + "&orgName=" + (urlParams.get("orgName") ? urlParams.get("orgName") : localStorage.getItem("ManageOrgName"));
    };
    const NotificationEmailUpdate = (event) => {

        if (validation.isNotificationEmailValid) {
            const updatedEmail = event.target.parentElement.parentElement.querySelector('input[type="email"]').value;

            setNotificationEmailAddress(updatedEmail); // Update the state with the new email address
            setSettings('NotificationEmail', updatedEmail); // Optiona
        }
        else {

            showToast("error", validation.notificationEmailErrorMessage);
        }
    };

    const handleNotificationTypeClick = (notificationType) => {
        setNotificationType(notificationType); // Update the state with the new checkbox value
        setSettings('NotificationType', notificationType);
    };

    const handleRestoreEmailTypeClick = (restoreMailTo) => {
        setRestoreEmailType(restoreMailTo); // Update the state with the new checkbox value
        setSettings('RestoreMailTo', restoreMailTo);
    };

    const adminEmailChange = (event) => {
        const updatedEmail = event.target.parentElement.parentElement.querySelector('input[type="email"]').value;

        setAdminEmailAddress(updatedEmail); // Update the state with the new email address
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (updatedEmail !== "" && updatedEmail.length < 510 && emailPattern.test(updatedEmail)) {
            setValidation({
                ...validation,
                isAdminEmailValid: true,
                adminEmailErrorMessage: ""
            });
        }
        else {
            if (updatedEmail === "") {
                setValidation({
                    ...validation,
                    isAdminEmailValid: false,
                    adminEmailErrorMessage: "Email should not be Empty"
                });
                //  showToast("error", "Email should not be Empty");
            }
            else if (!emailPattern.test(updatedEmail)) {
                setValidation({
                    ...validation,
                    isAdminEmailValid: false,
                    adminEmailErrorMessage: "Enter a valid email address"

                });
                // showToast("error", "Enter a valid email address");
            }
            else {
                setValidation({
                    ...validation,
                    isAdminEmailValid: false,
                    adminEmailErrorMessage: "Email Should not be more than 510 character "
                });
                // showToast("error", "Email Should not be more than 510 character");
            }
        }
    };

    const notificationEmailChange = (event) => {
        const updatedEmail = event.target.parentElement.parentElement.querySelector('input[type="email"]').value;

        setNotificationEmailAddress(updatedEmail); // Update the state with the new email address
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (updatedEmail !== "" && updatedEmail.length < 510 && emailPattern.test(updatedEmail)) {
            setValidation({
                ...validation,
                isNotificationEmailValid: true,
                notificationEmailErrorMessage: ""
            });
        }
        else {
            if (updatedEmail === "") {
                setValidation({
                    ...validation,
                    isNotificationEmailValid: false,
                    notificationEmailErrorMessage: "Email should not be Empty"
                });
                // showToast("error", "Email should not be Empty");

            }
            else if (!emailPattern.test(updatedEmail)) {
                setValidation({
                    ...validation,
                    isNotificationEmailValid: false,
                    notificationEmailErrorMessage: "Enter a valid email address"
                });
                //  showToast("error", "Enter a valid email address");
            }
            else {
                setValidation({
                    ...validation,
                    isNotificationEmailValid: false,
                    notificationEmailErrorMessage: "Email Should not be more than 510 character "
                });
                // showToast("error", "Email Should not be more than 510 character");
            }
        }
    };


    const adminEmailUpdate = (event) => {

        if (validation.isAdminEmailValid) {
            const updatedEmail = event.target.parentElement.parentElement.querySelector('input[type="email"]').value;

            setAdminEmailAddress(updatedEmail); // Update the state with the new email address
            setSettings('AdminEmail', updatedEmail); // Optiona
        }
        else {
            showToast("error", validation.adminEmailErrorMessage)
        }
    };
    const EncryptionKeyChange = (event) => {
        const updatedEncryption = event.target.parentElement.parentElement.querySelector('input[type="password"]').value;


        if (updatedEncryption === "") {
            setValidation({
                ...validation,
                isEncryptionValid: false,
                encryptionErrorMessage: "Encryption Key should not be Empty"
            });

        }
        else {
            setValidation({
                ...validation,
                isEncryptionValid: true,
                encryptionErrorMessage: ""
            });
        }

    };
    const encryptionUpdate = (event) => {

        const updatedEncryption = event.target.parentElement.parentElement.querySelector('input[type="password"]').value;

        if (updatedEncryption !== "" && validation.isEncryptionValid === true) {
            setEncryptionSettings(updatedEncryption);
        }
        else {
            showToast("error", "Please enter the encryption key")
        }

    };
    return (
        <Container width='100%' id="setting_container">
            <NavBar page="settings" authContext={authContext} />
            <div className="ps-2 pe-2 pb-3" style={{ maxHeight: '87vh', overflowY: 'auto', overflowX: 'hidden' }} >
                {isLoading ? (
                    <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '85vh' }}>
                        <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                    </div>
                ) : (

                    <Row>
                        {/* Delegated Management */}
                        <Col sm={12} md={6}  >
                            <Col md={12} className="mb-3">
                                <Card style={{}} id="DelegatedManagement">
                                    <Card.Header className="setting_header">DELEGATED MANAGEMENT</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Form.Label>If you enable this feature, administrators from your vendor's organisation will be able to perform the following tasks:</Form.Label>
                                        </Card.Text>
                                        <ul>
                                            <li>Create and administer backup jobs.</li>
                                            <li>Create and administer backup repositories.</li>
                                            <li>Perform restore tasks on behalf of your users.</li>
                                        </ul>

                                    <Form.Check type="checkbox" id="ChkBoxDelegatedMgt" label="Allow delegated management for my organisation" name="DelegatedManagement" checked={managed === 'true'} onChange={handleChkBoxDelegatedMgt}/>

                            </Card.Body>
                        </Card></Col>
                        <Col md={12} className="mb-3"> <Card style={{}}>
                            <Card.Header className="setting_header">UPDATE SETTINGS</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    This section should be used if requested by our support team, simply click the buttons below as directed.
                                    The process will update your configuration. No data or jobs will be altered.
                                </Card.Text>
                                <Form >
                                    <Form.Group controlId="formNotificationEmail" className="d-flex">
                                        <Button variant="primary" onClick={setUpClick} >Update Link</Button> &nbsp;&nbsp;&nbsp;
                                        <Button variant="primary" onClick={workerClick} >Update Workers</Button>
                                    </Form.Group>
                                </Form>

                                </Card.Body>
                            </Card></Col>
                            {resellerID && resellerID === "1" &&
                                <Col md={12} className="mb-3"> <Card style={{}}>
                                    <Card.Header className="setting_header">BILLING ADMINISTRATION</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            If you are paying by Direct Debit, you will receive invoices from this system, these will be sent to the address below:
                                        </Card.Text>
                                        <Form>
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Group controlId="formBillingEmail">

                                            <InputGroup className="mb-3">
                                                <Form.Control type="email" placeholder="Enter email" isInvalid={!validation.isAdminEmailValid} onChange={adminEmailChange} value={adminEmailAddress} />
                                                <Button variant="primary" onClick={adminEmailUpdate}>Update</Button>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                {validation.adminEmailErrorMessage}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        </Form>


                                </Card.Body>
                            </Card></Col>}
                        <Col md={12} className="mb-3"><Card style={{}} id="RestoreOptions">
                            <Card.Header className="setting_header">RESTORE EMAIL OPTIONS</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                   For security reasons you may wish to restrict where restore files can be mailed to.
                                </Card.Text>
                                
                                <Form>
                                  <Form.Label>Please select an email restore option:</Form.Label>
                                    <Form.Group controlId="formRestoreemailOption" className="d-flex">
                                        <Form.Check type="radio" id="emailOptionNoEmail" label="No Email" name="EmailOption" checked={restoreEmailType === "0"} onChange={() => handleRestoreEmailTypeClick("0")} />
                                        <Form.Check type="radio" id="emailOptionOrgOnly" label="Email to Organisation only" name="EmailOption" checked={restoreEmailType === "1"} onChange={() => handleRestoreEmailTypeClick("1")} /> 
                                        <Form.Check type="radio" id="emailOptionAnywhere" label="Email anywhere" name="EmailOption" checked={restoreEmailType === "2"} onChange={() => handleRestoreEmailTypeClick("2")} />
                                      
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card></Col>
                    </Col>
                    <Col sm={12} md={6} className="ps-0">
                        <Col md={12} className="mb-3"><Card style={{}} id="EndUserRecovery">
                            <Card.Header className="setting_header">END USER RECOVERY</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Form.Label>This functionality has now been replaced with a more granular control for selecting users who can perform restores:</Form.Label>
                                </Card.Text>
                                <ul>
                                    <li>Find the Veeam365EndUserRecovery group in your tenant.</li>
                                    <li>If it does not exist you can create it using the granular access section below.</li>
                                    <li>Add users or groups of users to this group.</li>
                                </ul>

                            {/*    <Form.Check type="checkbox" id="ChkBoxEndUserRecovery" label="Enable End User Recovery for my organisation" name="EndUserRecovery" checked={endUserRecovery === 'true'} onChange={handleendUserRecovery} disabled={isDisabled} />*/}
                            </Card.Body>
                        </Card></Col>
                        <Col md={12} className="mb-3"><Card style={{}} id="BackupNotification">
                            <Card.Header className="setting_header">BACKUP NOTIFICATIONS</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                            When configured, email notifications will be sent to the following address:
                                </Card.Text>
                                <Form>
                                    <Form.Group controlId="formNotificationEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="email" placeholder="Enter email" isInvalid={!validation.isNotificationEmailValid} onChange={notificationEmailChange} value={notificationEmailAddress}
                                            />
                                            <Button variant="primary" onClick={NotificationEmailUpdate}>Update</Button>
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            {validation.notificationEmailErrorMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                                <Form>
                                   <Form.Label>Please select a notification type:</Form.Label>
                                    <Form.Group controlId="formNotificationType" className="d-flex">
                                        <Form.Check type="radio" id="notificationTypeFail" label="Failure" name="notificationType" checked={notificationType === "fail"} onChange={() => handleNotificationTypeClick("fail")} />
                                        <Form.Check type="radio" id="notificationTypeWrng" label="Failure and Warning" name="notificationType" checked={notificationType === "fw"} onChange={() => handleNotificationTypeClick("fw")} />
                                        <Form.Check type="radio" id="notificationTypeNone" label="None" name="notificationType" checked={notificationType === "none"} onChange={() => handleNotificationTypeClick("none")} />
                                        <Form.Check type="radio" id="notificationTypeAll" label="All" name="notificationType" checked={notificationType === "all"} onChange={() => handleNotificationTypeClick("all")} />
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                                </Card></Col>
                                <Col md={12} className="mb-3">
                                    <Card id="GranularAccess">
                                        <Card.Header className="setting_header">GRANULAR ACCESS</Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Form.Label>
                                                    To enhance security, it's important to control who can perform specific backup and restore operations.These are the security groups we use and their permissions, if you did not select to add them at sign up you can configure them in your Tenancy.
                                                </Form.Label>
                                            </Card.Text>
                                            <Form>
                                                <div style={{ marginLeft: '25px' }}>
                                                    <div style={{ marginBottom: '8px' }}>
                                                        <ul>
                                                            <li style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Admin - Master Administration Accounts, complete access to all functions.</li>
                                                            <li style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Engineer - As Above, but cannot maintain system settings.</li>
                                                            <li style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365JobManagement - Can only manage and monitor jobs.</li>
                                                            <li style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Restore - Can only perform restore functions.</li>
                                                            <li style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365EndUserRecovery - Can only perform restore functions with their own Backup.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            {/*    <div style={{ marginLeft: '25px' }}>*/}
                                            {/*        <div style={{ marginBottom: '8px' }}>*/}
                                            {/*            <label style={{ cursor: 'pointer' }}>*/}
                                            {/*                <input*/}
                                            {/*                    type="checkbox"*/}
                                            {/*                    name="VeeamAdmin"*/}
                                            {/*                    checked={checkboxes.VeeamAdmin}*/}
                                            {/*                    // disabled={disabledCheckboxes.VeeamAdmin}*/}
                                            {/*                    disabled={true}*/}
                                            {/*                    onChange={handleCheckboxChange}*/}
                                            {/*                />*/}
                                            {/*                <span style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Admin - Master Administration Accounts, complete access to all functions.</span>*/}
                                            {/*            </label>*/}
                                            {/*        </div>*/}
                                            {/*        <div style={{ marginBottom: '8px' }}>*/}
                                            {/*            <label style={{ cursor: 'pointer' }}>*/}
                                            {/*                <input*/}
                                            {/*                    type="checkbox"*/}
                                            {/*                    name="Setting"*/}
                                            {/*                    checked={checkboxes.Setting}*/}
                                            {/*                    //disabled={disabledCheckboxes.Setting}*/}
                                            {/*                    disabled={true}*/}
                                            {/*                    onChange={handleCheckboxChange}*/}
                                            {/*                />*/}
                                            {/*                <span style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Engineer - As Above, but cannot maintain system settings.</span>*/}
                                            {/*            </label>*/}
                                            {/*        </div>*/}
                                            {/*        <div style={{ marginBottom: '8px' }}>*/}
                                            {/*            <label style={{ cursor: 'pointer' }}>*/}
                                            {/*                <input*/}
                                            {/*                    type="checkbox"*/}
                                            {/*                    name="MainPage"*/}
                                            {/*                    checked={checkboxes.MainPage}*/}
                                            {/*                    //disabled={disabledCheckboxes.MainPage}*/}
                                            {/*                    disabled={true}*/}
                                            {/*                    onChange={handleCheckboxChange}*/}
                                            {/*                />*/}
                                            {/*                <span style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365JobManagement - Can only manage and monitor jobs.</span>*/}
                                            {/*            </label>*/}
                                            {/*        </div>*/}
                                            {/*        <div style={{ marginBottom: '8px' }}>*/}
                                            {/*            <label style={{ cursor: 'pointer' }}>*/}
                                            {/*                <input*/}
                                            {/*                    type="checkbox"*/}
                                            {/*                    name="RestorePage"*/}
                                            {/*                    checked={checkboxes.RestorePage}*/}
                                            {/*                    //disabled={disabledCheckboxes.RestorePage}*/}
                                            {/*                    disabled={true}*/}
                                            {/*                    onChange={handleCheckboxChange}*/}
                                            {/*                />*/}
                                            {/*                <span style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365Restore - Can only perform restore functions.</span>*/}
                                            {/*            </label>*/}
                                            {/*        </div>*/}
                                            {/*        <div style={{ marginBottom: '8px' }}>*/}
                                            {/*            <label style={{ cursor: 'pointer' }}>*/}
                                            {/*                <input*/}
                                            {/*                    type="checkbox"*/}
                                            {/*                    name="EndUserRestore"*/}
                                            {/*                    checked={checkboxes.EndUserRestore}*/}
                                            {/*                    // disabled={disabledCheckboxes.EndUserRestore}*/}
                                            {/*                    disabled={true}*/}
                                            {/*                    onChange={handleCheckboxChange}*/}
                                            {/*                />*/}
                                            {/*                <span style={{ marginLeft: '6px', fontSize: '15px' }}>Veeam365EndUserRecovery - Can only perform restore functions with their own Backup.</span>*/}
                                            {/*            </label>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <Form.Label>*/}
                                            {/*        note: Created user groups cannot be deleted from the settings page.*/}
                                            {/*</Form.Label>*/}
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={12}><Card style={{}}>
                            <Card.Header className="setting_header">STORAGE ENCRYPTION</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    CloudCover 365 allows you to configure a password that you can use to encrypt data in object storage repositories using the AES-256 specification.
                                </Card.Text>
                                <Form>
                                    <Form.Group controlId="formStorageEncryption">
                                        <Form.Label>Encryption Key</Form.Label>
                                        {encryption === "true" ? (<Form.Control type="password" value="" disabled placeholder="Already Enabled" />
                                        )
                                            : (<InputGroup className="mb-3">
                                                <Form.Control type="password" isInvalid={!validation.isEncryptionValid} onChange={EncryptionKeyChange} placeholder="Enter Encryption Key" />
                                                <Button variant="primary" value="" onClick={encryptionUpdate}>Update</Button>
                                        </InputGroup>)
                                        }
                                        <Form.Control.Feedback type="invalid">
                                            {validation.encryptionErrorMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                   
                                </Form>
                            </Card.Body>
                        </Card></Col>
                        
                    </Col> 
                </Row>
                )}
            </div>

        </Container>
    );
}

export default Settings;
