import CryptoJS from 'crypto-js';

const decryptParameter = (encryptedParameter) => {
    try {
       // if (encryptedParameter === null || encryptedParameter.length === 0)
            return encryptedParameter;
        // Decode the encrypted parameter from Base64
       /* try {
            const decodedPara = atob(encryptedParameter);
            // If decoding succeeds, assume it's Base64 encoded and proceed with decryption
        } catch (error) {
            // If decoding fails, assume it's plaintext and return as is
            return encryptedParameter;
        }*/
       /* const encodedPara = decodeURIComponent(encryptedParameter.replace(/ /g, '+'));
       
        const decodedParameter = CryptoJS.enc.Base64.parse(encodedPara);
     
        // Decrypt the parameter using a secret key and IV
        const decryptedBytes = CryptoJS.AES.decrypt(
            { ciphertext: decodedParameter },
            CryptoJS.enc.Utf8.parse('YourSecretKey123'), // Secret key
            { iv: CryptoJS.enc.Utf8.parse('YourIV1234567890') } // IV
        );

        // Convert decrypted bytes to a readable string
        const decryptedParameter = decryptedBytes.toString(CryptoJS.enc.Utf8);
       
        if (decryptedParameter ===null || decryptedParameter.length === 0) {
         
            return encryptedParameter;
        }
    
        return decryptedParameter;*/
    } catch (error) {
      
        return encryptedParameter;
    }
};

export default decryptParameter;