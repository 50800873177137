import React from 'react';

function PageNotFound() {
    return (
        <div className="d-flex justify-content-center">
        <div>
                <h1>404 - Page Not Found</h1>
                <p className="text-center">The page you are looking for does not exist.</p>
            </div>
        </div>
    );
}

export default PageNotFound;