import React from "react";
import "../../CSS/general.css";

const ActionButtons = (props) => {
    const handleBack = () => {
       props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.closePopupModal();
    };

    const handleCancel = () => {
        props.closePopupModal();
    };

    return (
        <div>
            {props.currentStep < props.totalSteps && (
                <button onClick={handleNext} className="btn btn-primary btn-sm me-2">Next</button>
            )}
            {props.currentStep === props.totalSteps && (
                <button onClick={handleFinish} className="btn btn-primary btn-sm me-2">Finish</button>
            )}
            <button onClick={handleCancel} className="btn btn-outline-primary btn-sm">Cancel</button>
        </div>
    );
};
export default ActionButtons;