import React, { useEffect, useState } from "react";

const AlertModal = props => {
    const [message, setMessage] = useState("");
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10010"

    };

    const getMessage = () => {
        if (Array.isArray(props.message.message)) {
            var resp = props.message.message.map((item, index) => {
                return(<div>{item}</div>);
            })
            setMessage( resp);
        }
        else {
            setMessage(<div>{props.message.message}</div>);
        }
    }

    function closeModal(e) {
        e.stopPropagation();
        props.closeModal(true);
    }
    useEffect(() => {
        getMessage();
    }, [props.message.message]);

    return (
        <div >
            <div
               
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.message.header}
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" >
                            {message}
                            
                        </div>
                        <div className="modal-footer" >
                            <button
                                type="button"
                                onClick={closeModal}
                            >Ok
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="HelpModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>
    );
};

export default AlertModal;
