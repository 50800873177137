import React, { useState, createContext} from "react";

// set the defaults
export const AppContext = createContext();

export const AppContextProvider = props => {
    const [org, setOrg] = useState({
        orgId: "",
        orgName: "",
        orgDetails: "",
        tenantId:"",
        users: {
            isloaded: false,
            value: {}
        },
        groups: {
            isloaded: false,
            value: {}
        },
        sites: {
            isloaded: false,
            value: {}
        },
        teams: {
            isloaded: false,
            value: {}
        },
        job: {
                name: "",
                description: "",
                isEnabled: true,
                runNow: false,
                backupType: "SelectedItems",
                selectedItems: []
            },
        selectedObjects: [],
        excludedObjects: [],
        repositories: [],
        partialOrg: [],
        azureJobSession:"",
        isEditJob: false,
        isTeamsChatsOnline: false
    });
    const [navBarSetting, setNavBarSetting] = useState({
        orgId: "",
        orgName: "",
        impersonating: "",
        page: "",
        isAdmin: false,
        isReseller: false,
        isDirectCustomer: false,
        inAdminGroup: false,
        showHelpPages: false,
        fetchedData: false,
        licensedUsersCsv: "",
        restoreLogCsv: "",
        showdropdown: false,
        showadminFunctiondropdown: false,
        showReportsdropdown: false,
        showreports: false,
        accountInfoUrl: "",
        orgStatus: [],
        accountInfoHeader: "",
        showAccountInfo: false,

        showPriceFeature: false,
        priceFeatureInfoUrl: "",
        priceFeatureHeader: "",
        showDownloads: false,
        alertPop: false,
        alertMessage: [],
        priceModelName: "",
        isRestoreDownloadRefreshed: false
    });
    return (
        <AppContext.Provider value={[org, setOrg, navBarSetting, setNavBarSetting]}>
            {props.children}
        </AppContext.Provider>
        );
};

