import React, { Component } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Spinner,
} from "reactstrap";
export class HelpFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message,
    };
    this.showHelpPages = this.showHelpPages.bind(this);
    this.hideHelpPages = this.hideHelpPages.bind(this);
  }

  showHelpPages(event) {
    event.preventDefault();
    this.setState({
      showHelpPages: true,
    });
  }

  hideHelpPages(event) {
    event.preventDefault();
    this.setState({
      showHelpPages: false,
    });
  }

  render() {
    let helpModal = null;
    if (this.state.showHelpPages) {
      helpModal = (
        <Modal
          className="help-modal"
          size="xl"
          isOpen={this.state.showHelpPages}
          onClosed={this.hideHelpPages}
        >
          <table
            id="HelpPanel"
            style={{ border: "outset", backgroundColor: "#FFFFFF" }}
          >
            <tr
              id="PopupHeader"
              style={{ height: "36px", backgroundColor: "lightgray" }}
            >
              <td style={{ textAlign: "left", fontWeight: "500" }}>
                CloudCover 365 - Help
              </td>
              <td style={{ textAlign: "right" }}>
                <Button onClick={this.hideHelpPages}>X</Button>
              </td>
            </tr>
            <tr className="PopupBody">
              <td colspan="2">
                <iframe
                  id="HelpPopupFrame"
                  scrolling="auto"
                  style={{
                    width: "1000px",
                    height: "600px",
                    border: "hidden",
                    overflow: "no-display",
                  }}
                  src="https://cloudcover365.virtualdcs.co.uk/helpme/login.html"
                  runat="server"
                ></iframe>
              </td>
            </tr>
          </table>
        </Modal>
      );
    }
    return (
      <React.Fragment>
        {helpModal}
        {this.state.message}{" "}
          <a href="" onClick={this.showHelpPages} style={{ marginLeft: "5px" }}>
            Help
          </a>
      </React.Fragment>
    );
  }
}
