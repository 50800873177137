import React, { Component, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "reactstrap";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import 'react-toastify/dist/ReactToastify.css';
import decryptParameter from "../../Helpers/DeCryptParameter"; 
const ExLogin=(props)=>{
    const urlParams = new URLSearchParams(window.location.search);
   /* const orgId = decryptParameter(urlParams.get("orgId"));
    const orgName = decryptParameter(urlParams.get("orgName"));
    const pageName = decryptParameter(urlParams.get("pageName"));
    const _loginHint = decryptParameter(urlParams.get("loginHint"));*/

    const orgId = urlParams.get("orgId");
    const orgName = urlParams.get("orgName");
    const pageName = urlParams.get("pageName");
    const _loginHint = urlParams.get("loginHint");


    useEffect(() => {
        aquiretoken();
      var _account=loginAuthProvider.getAccountInfo();
    console.log(_account)
    if(!_account)
        loginAuthProvider.login();
      return () => {
        
      }
    }, []);

    const aquiretoken=async()=>{
        const accessTokenRequest = {
            scopes: [
                'User.Read',
                'User.Read.All',
                'User.ReadBasic.All',
                'Group.Read.All',
                'Domain.Read.All',
                'GroupMember.Read.All',
                'Directory.Read.All',
                'Application.ReadWrite.All',
                'User.ReadWrite.All'
            ],
            loginHint:_loginHint
            //User.ReadBasic.All and GroupMember.Read.All, User.Read.All and GroupMember.Read.All, User.ReadBasic.All and Group.Read.All, User.Read.All and Group.Read.All, Directory.Read.All
          };
        let re = await loginAuthProvider.acquireTokenSilent(accessTokenRequest).then(r => {
            console.log(r)
            return r.accessToken;
        }).catch((reason) => {
            console.log(reason);
        });
    }
   const RedirectFromExternal=()=>{
    setTimeout(() => {
        window.open(`${pageName}?orgId=${orgId}&orgName=${orgName}`,'_self');
        return;
    }, 400);
    return;
    }
    
    return (<div>
        <div>
        <AzureAD provider={loginAuthProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return(<>{ RedirectFromExternal()}</>)
                        case AuthenticationState.Unauthenticated:
                            if (error) {
                                return (
                                    <div>
                                        <p>
                                            CloudCover 365 uses your Microsoft account to authenticate
                                            you.
                                        </p>
                                       
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <p>
                                            CloudCover 365 uses your Microsoft account to authenticate
                                            you.
                                        </p>
                                       
                                    </div>
                                );
                            }
                        case AuthenticationState.InProgress:
                            return <div><p>Authenticating...</p><Button onClick={this.logout}>Cancel</Button></div>;
                        default:
                            return (
                                <div>
                                    <p>
                                        CloudCover 365 uses your Microsoft account to authenticate
                                        you.
                                    </p>
                                  
                                </div>
                            );
                    }
                }}
            </AzureAD>
        </div>
    </div>)
}
export default ExLogin;