import React, { Component } from "react";
import Maintenanceimg from "../../images/Maintenance.png";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "reactstrap";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import { RegCheck } from "../RegCheck";
import { HelpDocuments } from "../HelpDocuments";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import parse from 'html-react-parser';

export class Maintenance extends Component {
    
    render() {
        const FrontPageTheme = React.lazy(() => import('../ThemeObjects/FrontPage'));
        let chosenTheme = <FrontPageTheme />
        let themeObject = (
            <React.Suspense fallback={<></>}>
                {chosenTheme}
            </React.Suspense>);
        return (
            <div>
                {themeObject}
                <div className="row">
                    <div className="col-6">
                        <div className=" text-center p-3 justify-content-center d-flex align-items-center" style={{ height:'90vh' }}>
                            <div className="card" style={{ width: '30rem', borderRadius: '18px', border: '3px solid #4993dc' }}>
                                <div className="card-body" style={{ padding:'10% 17%' }}>
                                    <img src={Maintenanceimg} alt="Maintenanceimg" style={{ height: '7.5rem' }} />
                                    <p style={{ fontWeight: "500", fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>Our team is using this regular maintenance window to apply application updates, new features and security improvements.</p>
                                    <p style={{ fontWeight: "500", fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>Please try again soon.</p>
                                    <p style={{ fontWeight: "500", fontSize: '18px', fontFamily: "'Poppins', sans-serif" }}>In the meantime, why not try out our mini game 'Hacker Havoc'?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className=" text-center p-3 justify-content-center d-flex" style={{ height: '90vh' }}>
                            <div className="card" style={{ width: '100%', borderRadius: '18px', border: '3px solid #4993dc' }} >
                                <div className="card-body" style={{ overflow: 'auto', borderRadius: '18px' }}>
                                    <iframe id="hackerhavoc"
                                        src="https://hackerhavoc.cloudcover.services/"
                                        style={{ height: "51rem", width: "-webkit-fill-available" }}
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
