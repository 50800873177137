import { AgGridReact } from 'ag-grid-react';
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "../../CSS/general.css";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AppContext } from "./AppContext";
import HelpModal from "./HelpMessagePopup";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import decryptParameter from "../../Helpers/DeCryptParameter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Diff from "../../images/Diff.png";
import LoaderGif from "../../images/365 loading.gif";
import { toast } from 'react-toastify';

const JobHistory = ({ jobSessionUrl, token, jobSessionSelected, refresh }) => {
    const [jobSessions, setJobSessions] = useState([]);
    const [sessionId, setSessionId] = useState("");
    const [appOrg, setAppOrg] = useContext(AppContext);
    const [gridApi, setGridApi] = useState(null);
    const [helpPopup, setHelpPopup] = useState(true);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [retainSelection, setRetainSelection] = useState(true);
    const rowHeight = 25;
    const headerHeight = 30;
    const urlParams = new URLSearchParams(window.location.search);
    const orgName = urlParams.get("orgName");

    const getRowStyle = params => {

        /* if (params.node.rowIndex % 2 !== 0) {
             return { background: "white", borderColor: "white", fontSize: "Small", };
         } else {
             return { background: "#F0F0F0", borderColor: "#F0F0F0", fontSize: "Small" };
         }*/
        return { fontSize: "Small" };
    };

    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    const dateFormatter = (params) => {
        if (!params.value)
            return "";
        if (params.value.includes('T')) {
            var dateString = params.value.split('T');
            if (dateString.length > 0) {
                var timeString = dateString[1];
                var parseTime = timeString.substring(0, 8);
                return `${parseDate(dateString[0])} ${parseTime}`;
            }
        } else
            return params.Value
    };
    const entraDateFormatter = (params) => {
        let year = params.format("YYYY");
        let month = params.format("MM");
        let day = params.format("DD");
        let hours = params.format("HH");
        let mins = params.format("mm");
        let sec = params.format("ss");
        return `${day}-${month}-${year} ${hours}:${mins}:${sec}`;
    }

    const parseDate = (date) => {
        var parsedDate = date.split('-');
        return `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`;
    }

    const formatBytes = (params) => {
        if (params === 'undefined' || params === null)
            return;

        let bytes = params.value;
        if (bytes === 0) return '0 Bytes';
        let decimals = 2
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        loadData(params.api);
        /*  const interval = setInterval(() => {
              loadData(params.api);
          }, 15000)
          return () => {
              clearInterval(interval);
          }*/
        params.api.sizeColumnsToFit();

    }

    const onRowClicked = (params) => {
        if (jobSessionUrl === "Entra") {
            setAppOrg((prev) => {
                return {
                    ...prev,
                    'azureJobSession': params.data.creationTime

                }
            });
            jobSessionSelected("Entra");
        }
        else
            jobSessionSelected(params.data._links.log.href);
    }
    const downloadHtmlFile = (htmlString, filename) => {
        const newTab = window.open('', '_blank');
        newTab.document.documentElement.innerHTML = `
        <head>
            <title>${filename}</title>
        </head>
        <body>
            ${htmlString}
        </body>
    `;
        newTab.focus();
    };

    async function handleBlueprintReportDownload(filename) {
        const orgId = urlParams.get("orgId");
        const userName = localStorage.getItem("UserName");
        const config = {
            headers: {
                Authorisation: `Bearer ` + token,
            },
        };
        let toastId = toast.info("Your Blueprint report will be available in the Downloads section once it is ready.", { autoClose: true });

        const data = await Axios.get(API_ROUTE + Routes.GET_ENTRA_JOB_HISTORY_DOWNLOAD + "/" + appOrg.tenantId + "/" + filename + "/" + userName + "/" + orgId, config)
            .then((response) => {
                console.log(response);

                //if (response !== null)
                //    downloadHtmlFile(response.data, filename);
            })
            .catch((reason) => {
                console.log(reason);
            });
    }

    async function DownloadDiffReport(fileName) {

        const config = {
            headers: {
                Authorisation: `Bearer ` + token,
            },
        };
        let toastId = toast.info("Your Difference report is being prepared for download. The download will start shortly.", { autoClose: true });

        const data = await Axios.get(API_ROUTE + Routes.GET_ENTRA_BACKUP_DIFF_REPORT + "/" + appOrg.tenantId + "/" + fileName, config)
            .then((response) => {

                if (response !== null)
                    downloadHtmlFile(response.data, fileName);
            })
            .catch((reason) => {
                console.log("Download diff report failed");
            });
    }

    async function loadData(api) {

        setColumnDefinition(api);
        //if (api)
        //    return;
        if (jobSessionUrl === "Entra") {

            api.showLoadingOverlay();
            const config = {
                headers: {
                    Authorisation: `Bearer ` + token,
                },
            };
            const data = await Axios.get(API_ROUTE + Routes.GET_ENTRA_BACKUP_LOGS + "/" + appOrg.tenantId, config)
                .then((response) => {

                    api.hideOverlay();
                    if (response !== null)
                        return JSON.parse(response.data);
                })
                .catch((reason) => {
                });

            if (data) {
                var rowData = [];
                for (var i = 0; i < data.length; i++) {
                    var row = {
                        creationTime: entraDateFormatter(moment(data[i].startTime, "DD-MM-YYYY HH:mm:ss").locale('en')),
                        endTime: entraDateFormatter(moment(data[i].endTime, "DD-MM-YYYY HH:mm:ss").locale('en')),
                        process: data[i].process,
                        status: data[i].status,
                        diffCount: data[i].diffCount,
                        duration: `${moment(data[i].endTime, "DD-MM-YYYY HH:mm:ss").locale('en').diff(moment(data[i].startTime, "DD-MM-YYYY HH:mm:ss").locale('en'), 'minutes')}`,
                        bluePrint: data[i].bluePrint,
                        diffFile: data[i].diffFile,
                        jsonPrint: data[i].jsonPrint
                    }
                    rowData.push(row);
                }
                api.setRowData(rowData);
                api.hideOverlay();
                if (selectedRowIds.length > 0 && retainSelection) {
                    selectedRowIds.forEach((id) => {
                        let node = gridApi?.getRowNode(id)?.setSelected(true);
                    });

                }
            }
        }
        else if (jobSessionUrl === "") {
            api.setRowData([]);
        }
        else {
            api.showLoadingOverlay();
            const config = {
                headers: {
                    Authorisation: `Bearer ` + token,
                },
            };
            let payload = {
                url: jobSessionUrl,
                orgId: appOrg.orgId,
            };

            const data = await Axios.post(API_ROUTE + Routes.GET_DATA, payload, config)
                .then((response) => {

                    api.hideOverlay();
                    return JSON.parse(response.data).results;

                })
                .catch((reason) => {
                    console.log("loadData catch: " + reason.response.data);
                });
            if (data) {
                api.setRowData(data);
                api.hideOverlay();
                if (selectedRowIds.length > 0 && retainSelection) {
                    //let sel= selectedRowIds[0];
                    //gridApi?.getRowNode(sel)?.setSelected(true);
                    selectedRowIds.forEach((id) => {
                        let node = gridApi?.getRowNode(id)?.setSelected(true);
                    });
                }
            }
        }

    }

    const cellClicked = (params) => {
        params.node.setSelected(true)
    }
    function setColumnDefinition(api) {
        if (jobSessionUrl === "Entra") {
            var coldef = [
                {
                    field: 'creationTime', headerName: 'Start Time', comparator: function (date1, date2) {
                        // Custom comparator function for datetime sorting
                        if (date1 === undefined || date1 === null) {
                            // Treat undefined or null as greater than any defined date
                            return 1;
                        }
                        if (date2 === undefined || date2 === null) {
                            // Treat undefined or null as greater than any defined date
                            return -1;
                        }

                        return moment(date1, 'DD/MM/YYYY HH:mm:ss').diff(moment(date2, 'DD/MM/YYYY HH:mm:ss'));
                    }
                },
                {
                    field: 'endTime', headerName: 'End Time', comparator: function (date1, date2) {
                // Custom comparator function for datetime sorting
                if (date1 === undefined || date1 === null) {
                    // Treat undefined or null as greater than any defined date
                    return 1;
                }
                if (date2 === undefined || date2 === null) {
                    // Treat undefined or null as greater than any defined date
                    return -1;
                }

                        return moment(date1, 'DD/MM/YYYY HH:mm:ss').diff(moment(date2, 'DD/MM/YYYY HH:mm:ss'));
            }
                },
                {
                    field: 'process', headerName: 'Type'
                },
                { field: 'status', headerName: 'Status' },
                { field: 'diffCount', headerName: 'Difference count' },
                { field: 'duration', headerName: 'Duration (mins)' },
                {
                    field: 'bluePrint', headerName: 'Reports', cellRendererFramework: (params) => {
                        console.log("params",params)
                        if (params.data.bluePrint !== "") {
                            return (<>
                                <a id="ButtonDownloadJobHistory" title="Blueprint report" className="me-3"
                                    onClick={() => handleBlueprintReportDownload(params.data.bluePrint)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" fill="currentColor">
                                        <path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32v96V384c0 35.3 28.7 64 64 64H256V384H64V160H256V96H64V32zM288 192c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4L409.4 9.4c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V192zm0 288c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4l-13.3-13.3c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V480z" />
                                    </svg>
                                </a >
                                {params.data.diffCount!=="0"?( <a id="ButtonDownloadDiffReport" title="Difference report"
                                    onClick={() => DownloadDiffReport(params.data.diffFile)}>
                                    <img style={{ height:'39px' }} src={Diff}></img>
                                </a >) : (<>--</>)}
                            </>
                            );
                        }
                        else {
                            return <div>
                                <span className="me-4">--</span>
                            <span>--</span>
                            </div>
                        }
                    } }
            ];
            api.setColumnDefs(coldef);
        } else {
            var coldefs = [
                {
                    field: 'creationTime', headerName: 'Start Time', valueFormatter: dateFormatter, minWidth: 160, flex: 1,
                    getQuickFilterText: params => {
                        return dateFormatter(params);
                    }
                },
                {
                    field: 'endTime', headerName: 'End Time', valueFormatter: dateFormatter, minWidth: 160, flex: 1,
                    getQuickFilterText: params => {
                        return dateFormatter(params);
                    }
                },
                { field: 'status', headerName: 'Status', minWidth: 100,  flex: 1 },
                {
                    field: 'statistics.processingRateBytesPS', headerName: 'Processing Rate', valueFormatter: formatBytes, minWidth: 130, flex: 1
                },
                {
                    field: 'statistics.transferredDataBytes', headerName: 'Transferred', valueFormatter: formatBytes, minWidth: 160, flex: 1
                },
                { field: 'statistics.processedObjects', headerName: 'Items Transferred', minWidth: 160,  flex: 1 },
            ];
            api.setColumnDefs(coldefs);
        }
    }
    useEffect(() => {
        if (gridApi) {
            setRetainSelection(false);
            loadData(gridApi);
        }
    }, [jobSessionUrl]);

    useEffect(() => {
        if (gridApi) {
            setRetainSelection(true);
            loadData(gridApi);
        }
    }, [refresh]);

    function closeModal(params) {
        setHelpPopup(params);
    }

    return (
        <div className="card mt-1" id="jobHistory">
            <div className="card-header p-1 d-flex align-items-center">
                <span className="SectionTitle ms-2" style={{ fontWeight: '500', width: '50%' }}> Job History
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-jobhistory">Select a job from above</Tooltip>}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-2 bi bi-info-circle" viewBox="0 0 16 16" style={{ position: 'absolute' }}>
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                    </OverlayTrigger>
                </span>
                <div className="d-flex justify-content-end " style={{ width: '50%' }}>
                    <a id="btnReload_jobHistory" onClick={() => loadData(gridApi)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 512 512" className="mb-1 me-2">
                                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z">
                                </path>
                            </svg> </a>

                    <a id="LinkButton2" onClick={() => closeModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 384 512" className="mb-1 me-1">
                            <g >
                                <path fill="currentColor" d="M182.4,373.5c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3c38.3,0,69.3-31,69.3-69.3 C251.7,404.5,220.7,373.5,182.4,373.5z"></path><path fill="currentColor" d="M367.9,153.6c0,116-125.3,117.8-125.3,160.6v5.8c0,13.3-10.7,24-24,24h-72.5c-13.3,0-24-10.7-24-24v-9.8 c0-61.8,46.9-86.5,82.3-106.4c30.4-17,49-28.6,49-51.2c0-29.8-38-49.6-68.8-49.6c-39.1,0-57.8,18.1-82.8,49.4 c-8.1,10.2-22.9,12-33.3,4.1l-43.1-32.7c-10.3-7.8-12.6-22.3-5.2-32.9C60.9,32.7,112.6,0,192.4,0C277.3,0,367.9,66.3,367.9,153.6z">
                                </path>
                            </g>
                        </svg></a>
                </div>
            </div>
            <div className="card-body p-0">
                <div id="ContentPlaceHolder1_UpdatePanelJobList">
                    <div id="job-history-panel" style={{ height: "231px", width: "100%", overflow: "auto" }}>
                        {(jobSessionUrl.length > 0) ?
                            (
                                <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 231, borderCollapse: "collapse" }} >
                                    <AgGridReact style={{ width: "100%" }}
                                        onCellClicked={cellClicked}
                                        getRowStyle={getRowStyle}
                                        onRowClicked={onRowClicked}
                                        onGridReady={onGridReady}
                                        defaultColDef={defaultColDef}
                                        enableCellTextSelection="true"
                                        ensureDomOrder="true"
                                        onFirstDataRendered={onFirstDataRendered}
                                        rowSelection="single"
                                        rowHeight={rowHeight}
                                        headerHeight={headerHeight}
                                        rowBuffer={500}
                                        pagination={true}
                                        paginationPageSize={50}
                                        overlayLoadingTemplate={
                                            `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`
                                        }
                                        overlayNoRowsTemplate={
                                            '<span className="ag-overlay-loading-center">No records to show</span>'
                                        }
                                        suppressScrollOnNewData={true}
                                        onSelectionChanged={(e) => {
                                            const selectedNodes = e.api.getSelectedNodes();
                                            if (selectedNodes.length === 0) return;
                                            const selectedRowIds = selectedNodes
                                                .map((n) => n.id);
                                            setSelectedRowIds(selectedRowIds);
                                        }}
                                        columnDefs={[]} >
                                    </AgGridReact>
                                </div>
                            )
                            :
                            <div></div>}
                    </div>
                </div>
            </div>
            <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="JobHistory.html" />
        </div>
    );
}
export default JobHistory;



