import React, { Component } from "react";
import Register from "../../images/Register.png";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { Image } from "semantic-ui-react";
import { authProvider } from "../../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { AzureAD, AuthenticationState } from "react-aad-msal";
//import "../../CSS/frontPage.css";
import { RegistrationProgressLegacy } from "../RegistrationProgressLegacy";
import Cookies from "universal-cookie";
import still from "../../images/setupimages/signup.png";
import setupbanner from "../../images/setupimages/setupbanner.png";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import LogRocket from 'logrocket';
import { HelpDocuments } from "../HelpDocuments";

export class SignUpLegacy extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const tenantId = urlParams.get('tenant');
    var autoLogin = false;
    if(tenantId){
      autoLogin = true;
    }
    this.state = {
      progress: 0,
      inProgress: 0,
      autoLogin: autoLogin,
      getFlag: false,
      mandateCreated: false,
      responseData: null,
      showWarningText: false,
      callbackFlag: false,
      warningText: "",
      authErrorChecked: false,
      tenantId: tenantId
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }
  static displayName = SignUpLegacy.name;

  login(event){
    event.preventDefault();
    authProvider.login();
  }

  logout(event) {
    event.preventDefault();
    authProvider.logout();
    window.location = "/Logout";
  }

  render() {
    var registerButton = (
      <AzureAD provider={authProvider} forceLogin={this.state.autoLogin}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              LogRocket.identify(accountInfo.account.userName, {
                name: accountInfo.account.name
              });
              return (
                <React.Fragment>
                  <div>
                    <RegistrationProgressLegacy tenantId={this.state.tenantId} autoLogin={this.state.autoLogin}/>
                  </div>
                </React.Fragment>
              );
            case AuthenticationState.Unauthenticated:
              if (error) {
                if(error.errorCode){
                  if(error.errorCode == "user_cancelled"){
                    return (
                      <React.Fragment>
                        <div className="card-deck">
                          <div className="card shadow p-2 mb-3 bg-white rounded">
                            <div className="card-body text-center" style={{padding: "0"}}>
                              <br />
                              <br />
                              <p
                                className="card-text"
                                style={{ fontSize: "large" }}
                              >
                                Please login with your credentials, you must be a
                                Global Admin to perform this setup.
                                <br/>                             
                                Please ensure that pop-ups are not blocked.
                              </p>
                              <Image
                                src={microsoftSignInDarkBig}
                                alt="Sign In"
                                onClick={this.login}
                              />
                              <br/>
                              <br/>
                              <p className="card-text"
                                style={{ fontSize: "medium" }}>
                              If you want more information about what we are going to do please click the help link below.
                              </p>
                            </div>
                          </div>
                          <div className="card shadow p-2 mb-3 bg-white rounded">
                            <div className="card-body text-center" style={{padding: "0"}}>
                              <Image
                                style={{ width: "90%" }}
                                src={still}
                                alt="CloudCover365"
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment>
                        <div className="card-deck">
                          <div className="card shadow p-2 mb-3 bg-white rounded">
                            <div className="card-body text-center" style={{padding: "0"}}>
                              <br />
                              <br />
                              <p className="card-text" style={{ fontSize: "large" }}>
                                Authentication Error. Please log out and try again.
                              </p>
                              <Button onClick={logout}>Log Out</Button>
                            </div>
                          </div>
                          <div className="card shadow p-2 mb-3 bg-white rounded">
                            <div className="card-body text-center" style={{padding: "0"}}>
                              <Image
                                style={{ width: "90%" }}
                                src={still}
                                alt="CloudCover365"
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ); 
                  }
                } else {
                  return (
                    <React.Fragment>
                      <div className="card-deck">
                        <div className="card shadow p-2 mb-3 bg-white rounded">
                          <div className="card-body text-center" style={{padding: "0"}}>
                            <br />
                            <br />
                            <p className="card-text" style={{ fontSize: "large" }}>
                              Authentication Error. Please log out and try again.
                            </p>
                            <Button onClick={this.logout}>Log Out</Button>
                          </div>
                        </div>
                        <div className="card shadow p-2 mb-3 bg-white rounded">
                          <div className="card-body text-center" style={{padding: "0"}}>
                            <Image
                              style={{ width: "90%" }}
                              src={still}
                              alt="CloudCover365"
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ); 
                }                           
              } else {
                return (
                  <React.Fragment>
                    <div className="card-deck">
                      <div className="card shadow p-2 mb-3 bg-white rounded">
                        <div className="card-body text-center" style={{padding: "0"}}>
                          <br />
                          <br />
                          <p
                            className="card-text"
                            style={{ fontSize: "large" }}
                          >
                            Please login with your credentials, you must be a
                            Global Admin to perform this setup.
                            <br/>                             
                            Please ensure that pop-ups are not blocked.
                          </p>
                          <Image
                            src={microsoftSignInDarkBig}
                            alt="Sign In"
                            onClick={this.login}
                          />
                          <br/>
                          <br/>
                          <p className="card-text"
                            style={{ fontSize: "medium" }}>
                          If you want more information about what we are going to do please click the help link below.
                          </p>
                        </div>
                      </div>
                      <div className="card shadow p-2 mb-3 bg-white rounded">
                        <div className="card-body text-center" style={{padding: "0"}}>
                          <Image
                            style={{ width: "90%" }}
                            src={still}
                            alt="CloudCover365"
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            case AuthenticationState.InProgress:
              return (
                <React.Fragment>
                  <div className="card-deck">
                    <div className="card shadow p-2 mb-3 bg-white rounded">
                      <div className="card-body text-center" style={{padding: "0"}}>
                        <br />
                        <br />
                        <p className="card-text" style={{ fontSize: "large" }}>
                          Authenticating with Microsoft.
                        </p>
                      </div>
                    </div>
                    <div className="card shadow p-2 mb-3 bg-white rounded">
                      <div className="card-body text-center" style={{padding: "0"}}>
                        <Image
                          style={{ width: "90%" }}
                          src={still}
                          alt="CloudCover365"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            default:
              return <p>Error.</p>;
          }
        }}
      </AzureAD>
    );
    const FrontPageTheme = React.lazy(() => import('../ThemeObjects/FrontPage'));
    let chosenTheme = <FrontPageTheme/>
    let themeObject = (
      <React.Suspense fallback={<></>}>
        {chosenTheme}
      </React.Suspense>);
      const currentYear = new Date().getFullYear(); 
    return (
      <div id="ContentPlaceHolder1_UpdatePanel1">
        {themeObject}
        <div className="row login">
          <div className="col"></div>
          <div className="col-lg-7 text-center" style={{padding: "0"}}>
            <div className="card loginbox">
              <div className="card-body">
                <div style={{ display: "inline-block" }} className="mb-2">
                  <Image
                    src={setupbanner}
                    alt="Cloudcover 365"
                    style={{ display: "inline-block", width: "50%" }}
                  />
                </div>
                
                <p className="card-text" style={{ fontSize: "large" }}>
                  To backup your Microsoft 365, we need to create links
                  between your tenant, CloudCover 365 and our Veeam
                  backup engine. To make this easier for you we have automated
                  the process as much as possible. During busy times, calls to Microsoft may be slow and as such we ask that you please wait until each step finishes.
                </p>
                <div>{registerButton}</div>
                <HelpDocuments message="Having trouble registering?" />
              </div>
              <div className="card-footer">
                <section className="small">
                  <SubscriberAgreement />
                  |
                  <SecurityAndPrivacy />
                                <br />© Saas It {currentYear}
                </section>
              </div>

              <span
                id="ContentPlaceHolder1_lblLoginResponse"
                style={{ color: "red" }}
              ></span>
            </div>
          </div>
          <div className="col-sm"></div>
        </div>
      </div>
    );
  }
}
