import React, { Component } from "react";
import loginIcon from "../../images/loginIcon.PNG";
import newloginIcon from "../../images/icon.png";
import microsoftSignInDarkBig from "../../images/microsoftSignInDarkBig.png";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "reactstrap";
import { LEGACY_SITE, API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { SubscriberAgreement } from "../SubscriberAgreement";
import { SecurityAndPrivacy } from "../SecurityAndPrivacy";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import LogRocket from "logrocket";
import { HelpDocuments } from "../HelpDocuments";
import { HelpFooter } from "../HelpFooter";
import parse from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import Axios from "axios";


export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
      this.logout = this.logout.bind(this);
      this.showNotifications = this.showNotifications.bind(this);
  }
  static displayName = Home.name;
    componentDidMount() {
        this.showNotifications();
    }
  logout(event) {
    event.preventDefault();
    loginAuthProvider.logout();
    window.location = "/Logout";
  }
    async showNotifications() {
        const config = {
            headers: {
                Authorisation: `Bearer NOT REQUIRED`,
            },
        };

        let resData = await Axios.get(API_ROUTE + Routes.GET_NOTIFICATIONS, config)
            .then((response) => {
                let data = JSON.parse(response.data);
                for (var i = 0; i < data.length; i++) {
                    let item = data[i];
                    var element = parse(item.message);
                    toast(({ closeToast }) => <div>{parse(item.message)} </div>, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: false });
                }

                return data;
            })
            .catch((reason) => {
                console.log("catch Notification: " + reason);
            });
    }
  render() {
    var accountInfo = loginAuthProvider.getAccountInfo();
    var email = "";
    var name = "";
    if (accountInfo) {
      email = accountInfo.account.userName;
      name = accountInfo.account.name;
    }

    let loginButton = (
      <AzureAD provider={loginAuthProvider} forceLogin={false}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              LogRocket.identify(accountInfo.account.userName, {
                name: accountInfo.account.name
              });
              return (
                <React.Fragment>
                  <div>
                          <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                      CloudCover 365 uses your Microsoft account to authenticate
                      you.
                    </p>
                          <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                      You are logged in as {accountInfo.account.name} (
                      {accountInfo.account.userName})
                    </p>
                          <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Use these credentials to login to CloudCover 365?</p>
                    <a style={{ cursor: "pointer" }} href={"/Login?autoLogin=true&email=" + accountInfo.account.userName}>
                      <img
                        src={microsoftSignInDarkBig}
                        alt="Sign In"
                        href={"/Login?autoLogin=true&email=" + accountInfo.account.userName}
                        style={{ borderRadius: '0.375rem' }}
                      />
                    </a>
                  </div>
                  <br/>
                  <div>
                    <Button onClick={this.logout} color="dark">Log Out</Button>
                  </div>
                </React.Fragment>
              );
            case AuthenticationState.Unauthenticated:
              return (
                  <div>
                      <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                    CloudCover 365 uses your Microsoft account to authenticate
                    you.
                  </p>
                  <a style={{ cursor: "pointer" }} href="/Login">
                    <img
                        src={microsoftSignInDarkBig}
                        alt="Sign In"
                        href="/Login"
                        style={{ borderRadius:'0.375rem' }}
                    />
                  </a>
                </div>
              );
            case AuthenticationState.InProgress:
                  return <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Authenticating...</p>;
            default:
                  return <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Error. Please make sure pop-ups are enabled.</p>;
          }
        }}
      </AzureAD>
    );
    const FrontPageTheme = React.lazy(() => import('../ThemeObjects/FrontPage'));
    let chosenTheme = <FrontPageTheme/>
    let themeObject = (
      <React.Suspense fallback={<></>}>
        {chosenTheme}
          </React.Suspense>);

      const currentYear = new Date().getFullYear(); 
    return (
        <div id="ContentPlaceHolder1_UpdatePanel1" style={{ maxHeight: '90vh', overflowX: 'hidden', overflowY: 'auto' }}>
            {themeObject}
            <div className="row login" style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center', height:'90vh' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 text-center">
                        <div className="card loginbox" style={{ borderRadius: '18px', border:'3px solid #4993dc' }}>
                            <div className="card-body" style={{ paddingLeft: '17%', paddingRight: '17%' }}>
                                <img src={newloginIcon} alt="Login" style={{ height:'65px' }} />
                                <h4>Please log in</h4>
                                <div
                                    id="ContentPlaceHolder1_UsernameBasic"
                                    className="form-group"
                                ></div>
                                {loginButton}
                                <br />
                                <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Having trouble logging in? Try our live support below.</p>
                            </div>
                            <div className="card-footer" style={{ marginLeft: '8%', marginRight: '8%', marginBottom:'8%', border: '1px solid lightgray', borderRadius: '10px' }}>
                                <section className="o-panel__section reg-now-content text-center">
                                    <h5 className="small-text__light" id="login-offline-customer">
                                        Do you need to register? Sign up now to protect your data
                                    </h5>
                                    <a id=" " href="/SignUp" className="btn btn-primary" style={{ fontWeight:'700' }}>
                                        Continue
                                    </a>
                                    <hr />
                                </section>
                                <section className="small">
                                    <SubscriberAgreement />
                                    |
                                    <SecurityAndPrivacy />
                                    |
                                    <HelpFooter />
                                    <br />© Saas It {currentYear}
                                </section>
                            </div>

                            <span
                                id="ContentPlaceHolder1_lblLoginResponse"
                                style={{ color: "red" }}
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer limit={4} style={{ fontSize: "small", top: "80px", right: "5px" }} />
      </div>
    );
  }
}
