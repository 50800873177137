import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
  auth: {
    authority: "https://login.microsoftonline.com/common", //Directory (tenant) ID Overview blade of App Registration
    clientId: "f6646bf4-b492-4130-b548-84132a3a38e6", //Application (client) ID
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

export const authenticationParameters = {
  scopes: [
    'email',
    'offline_access',
    'openid',
    'Organization.Read.All',
    'profile',
    'User.Read',
    'Application.ReadWrite.All',
    //'Application.Read.All',
    'Group.ReadWrite.All',
    //'Group.Read.All',
    'User.ReadWrite.All',
    //'User.ReadWrite',
    'RoleManagement.Read.Directory', 
    //'Directory.Read.All', 
    'RoleManagement.ReadWrite.Directory', 
    'Directory.ReadWrite.All', 
    'Directory.AccessAsUser.All',
    'Policy.ReadWrite.ConditionalAccess',
    'Policy.Read.All',
    'Agreement.Read.All'
  ]
}

export const authenticationParametersGraph = {
  scopes: [
    'openid'
  ]
}

export const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin,  
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)