import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "../CSS/general.css";
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import exchange from "../images/exchange.png";
import onedrive from "../images/onedrive.png";
import sharepoint from "../images/sharepoint.png";
import teams from "../images/teams-icon.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoaderGif from "../images/365 loading.gif";

export const RestorePoints = (props) => {
    const [restorePoint, setRestorePoint] = useState([]);
    const [gridApi, setGridApi] = useState([]);
    const onGridReady = (params) => {
        setGridApi(params.api);
        //params.api.sizeColumnsToFit();
    }

    const rowHeight = 25;
    const headerHeight = 30;
    const gridRef = useRef();
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };
    const defaultColDef = {
        sortable: true,
        resizable: true,
    };

    const columnDefs = [
        {
            field: 'backupTimeStr', headerName: 'backupTime', cellStyle: { 'text-align': 'left' }, minWidth: 130, flex: 1,
            headerComponentParams: {
                template: ` <div class="ag-grid-header-calendar" style="width:100%"><i style="color: white !important;" class="fa fa-calendar "/></div>`
            }
        },  
        {
            field: 'isExchange', headerName: '', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 38, flex: 1,
            headerComponentParams: {
                template: ` <img src=${exchange} alt="Exchange Restore" style="width: 1.2vw; height: 1.2vw;" class="ag-grid-header" />`
            },
            cellRendererFramework: (params) => <div>
                {params.value && <i className="fa fa-check"></i>
                }
                {!params.value && <i className="fa fa-times"></i>
                }
            </div>

        },
        {
            field: 'isSharePoint', headerName: 'Sharepoint', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 38, flex: 1,
            headerComponentParams: {
                template: ` <img src=${sharepoint} alt="Exchange Restore" style="width: 1.2vw; height: 1.2vw;" class="ag-grid-header" />`
            },
            cellRendererFramework: (params) => <div>
                {params.value && <i className="fa fa-check" ></i>
                }
                {!params.value && <i className="fa fa-times" ></i>
                }
            </div>
         
        },
        {
            field: 'isOneDrive', headerName: 'OneDrive', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 38, flex: 1,
            headerComponentParams: {
                template: ` <img src=${onedrive} alt="Exchange Restore" style="width: 1.2vw; height: 1.2vw;" class="ag-grid-header" />`
            },
            cellRendererFramework: (params) => <div>
                {params.value && <i className="fa fa-check"></i>
                }
                {!params.value && <i className="fa fa-times"></i>
                }
            </div>
        },
        {
            field: 'isTeams', headerName: 'Teams', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 38, flex: 1,
            headerComponentParams: {
                template: ` <img src=${teams} alt="Exchange Restore" style="width: 1.2vw; height: 1.2vw;" class="ag-grid-header" />`
            },
            cellRendererFramework: (params) => (<div>
                {params.value && <i className="fa fa-check"></i>
                }
                {!params.value && <i className="fa fa-times"></i>
                }
            </div>),
        hide: props.isEndUser
        },
    ];

    const onRowClicked = (params) => {
        setRestorePoint(params.data);
        props.setRestorePoint(params.data)
        props.popupSwap();
    }




    return (
        <>
            
                <div className="card-body p-0">
                    { (props.restorePoints.length>0)?
                        (<div id="restorePointList" className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 300, borderCollapse: "collapse" }} >

                            <AgGridReact
                                ref={gridRef}
                                rowData={props.restorePoints}
                                enableCellTextSelection="true"
                                getRowStyle={getRowStyle}
                                onRowClicked={onRowClicked}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                rowSelection="single"
                                columnDefs={columnDefs}
                                width={700}
                                headerHeight={headerHeight}
                            rowHeight={rowHeight}
                            overlayLoadingTemplate={
                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:50px;"></div>`}
                            >
                            </AgGridReact>
                        </div>) :
                        <div>Please wait while restore points are loading...</div>
                    }
                </div>
            
        </>
    );

}