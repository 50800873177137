/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { loginAuthProvider } from "../../LoginAuthProvider";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import Axios from "axios";

const ContractCommit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orgId = urlParams.get("signkey") ? urlParams.get("signkey") : "";
    const isReseller = urlParams.get("reseller") ? urlParams.get("reseller") : "";
    const isDirectCustomer = urlParams.get("directCustomer") ? urlParams.get("directCustomer") : "";
    const status = urlParams.get("status") ? urlParams.get("status") : "";
    const userPrice = urlParams.get("userPrice") ? urlParams.get("userPrice") : "";
    const priceName = urlParams.get("priceName") ? urlParams.get("priceName") : "";
    const quotaValue = urlParams.get("quotaValue") ? urlParams.get("quotaValue") : "";
    const termValue = urlParams.get("termValue") ? urlParams.get("termValue") : "";
    const isManaged = urlParams.get("isManaged") ? urlParams.get("isManaged") : "";
    
    const [nextUrl] = useState("/DirectDebitSettings?signkey=" + orgId);
    function handleButtonClick() {
        window.parent.postMessage('changeUrl', '*'); 
    }

    return (
        <div>
            <div className="row login">
                <div className="col text-center">
                    <div className="card">
                        {status === 'Active' ? 
                            <div className="card-body">
                                <p>If you need to change the bank account on your mandate please click the button below.</p>
                                <a href={nextUrl} className="btn btn-primary mt-3 mb-3" disabled>Managed Current Direct Debit</a>
                                <input type="hidden" id="PAYGPriceModelID" />
                                <input type="hidden" id="ContractPriceModelID" />
                                <br />
                                <span id="status" style={{ color: "#FF3300" }}></span>

                                <small>By creating a direct debit agreement you are accepting the terms of our &nbsp;
                                    <a href="https://www.virtualdcs.co.uk/download/subscriber-agreement.pdf" target="_blank">Subscriber Agreement</a>&#44;  and also confirming you have the authority to do so on behalf of your organisation.
                                </small>
                            </div>
                            : ((isReseller == "true" && isManaged == "true") || (isReseller=="false"  && (isDirectCustomer === false || isDirectCustomer === "false")) )? 
                                <div className="card-body">
                                    <p>Your account is currently not active, to continue to use this service you must talk to your service provider.</p>
                                </div>
                                : (status === 'Failed' || status === 'NoGC')  &&  
                                <div className="card-body">
                                    <p>Your account is currently not active, to continue to use this service you must have a live Direct Debit, your current package is as follows.</p>
                                    <div className="card-deck text-center">
                                        <div className="card shadow-sm">
                                            <div className="card-header">
                                                    <h4 className="my-0 font-weight-normal">{priceName}
                                                {/*    <span><i className="fad fa-shield-check"></i></span>*/}
                                                </h4>
                                            </div>
                                                <div className="card-body">
                                                    <h3 className="card-title pricing-card-title">{userPrice}<span className="text-muted small">/User</span><br /></h3>
                                                    <span className="text-muted small" style={{ fontWeight: 'bold' }}>Term : {termValue}</span>
                                                    <p>Disk Space outside of quota will be charged at &#163;{quotaValue}<br /></p>

                                            </div>
                                        </div>
                                    </div>
                                    <a href={nextUrl} className="btn btn-primary mt-3 mb-3" disabled>Activate your account now</a>
                                    <input type="hidden" id="PAYGPriceModelID" />
                                    <input type="hidden" id="ContractPriceModelID" />
                                    <br />
                                    <span id="status" style={{ color: "#FF3300" }}></span>
                                        <button onClick={handleButtonClick} className="btn btn-primary mt-3 mb-3">Select Package</button>
                                        <br />
                                        <small>By creating a direct debit agreement you are accepting the terms of our &nbsp;
                                            <a href="https://www.virtualdcs.co.uk/download/subscriber-agreement.pdf" target="_blank">Subscriber Agreement</a>&#44;  and also confirming you have the authority to do so on behalf of your organisation.
                                        </small>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContractCommit;